<section class="section clients-section">
  <div>
    <div *ngIf="mobile === true">
      <h2 class="section-heading">
        Kunden von <br />
        MA-Technology
      </h2>
    </div>
    <div *ngIf="mobile !== true">
      <h2 class="section-heading">Kunden von MA-Technology</h2>
    </div>

    <div fxLayout="row wrap" class="clients">
      <div
        fxLayoutAlign="center center"
        *ngFor="let client of clients"
        class="client"
        fxFlex.gt-xs="25%"
        fxFlex.xs="50%"
        rel="noopener noreferrer"
      >
        <img
          id="icon-custom{{ client.id }}"
          class="icon-custom"
          [src]="client.img"
          alt="Daimler Logo"
        />
        <div class="client-name">{{ client.name }}</div>
      </div>
    </div>
  </div>
</section>
