import {
  animate,
  keyframes,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const navItemsFadeInLeft = trigger('navItemsFadeInLeft', [
  transition('* => *', [
    query(
      ':enter',
      [
        style({
          opacity: 0,
          transform: 'translateX(-50%)',
        }),
        stagger(100, [
          animate(
            '0.5s ease-in',
            keyframes([
              style({
                opacity: 0.5,
                transform: 'translateX(-10px) scale(1.1)',
              }),
              style({ opacity: 1, transform: 'translateX(0)' }),
            ])
          ),
        ]),
      ],
      { optional: true }
    ),
  ]),
]);

export const heroItemsFadeInTop = trigger('heroItemsFadeInTop', [
  transition('* => *', [
    query(
      '.word',
      [
        style({
          opacity: 0,
          transform: 'translateY(100%)',
        }),
        stagger(1000, [
          animate(
            '0.5s ease-in',
            keyframes([
              style({
                opacity: 0.5,
                transform: 'translateY(-10px) scale(1.1)',
              }),
              style({ opacity: 1, transform: 'translateY(0)' }),
            ])
          ),
        ]),
      ],
      { optional: true }
    ),
  ]),
]);

export const skillsFadeInBottom = trigger('skillsFadeInBottom', [
  transition('false => true', [
    query(
      '.skill',
      [
        style({
          opacity: 0,
          transform: 'translateY(50%)',
        }),
        stagger(100, [
          animate(
            '0.5s ease-in',
            keyframes([
              style({
                opacity: 0.5,
                transform: 'translateY(-10px) scale(1.1)',
              }),
              style({ opacity: 1, transform: 'translateY(0)' }),
            ])
          ),
        ]),
      ],
      { optional: true }
    ),
  ]),
]);

export const projectsFadeIn = trigger('projectsFadeIn', [
  transition('false => true', [
    query(
      '.project',
      [
        style({
          opacity: 0,
        }),
        stagger(300, [animate('0.5s ease-in', style({ opacity: 1 }))]),
      ],
      { optional: true }
    ),
  ]),
]);
