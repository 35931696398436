import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-input-data',
  templateUrl: './input-data.component.html',
  styleUrls: ['./input-data.component.scss'],
})
export class InputDataComponent implements OnInit {
  user = ['MA-Technology', 'Angulex'];
  formGroup: FormGroup = this.fb.group({
    name: [null, [Validators.required]],
    password: [null, [Validators.required]],
  });

  constructor(private fb: FormBuilder, private sharedService: SharedService) {}

  ngOnInit(): void {}

  onSubmit() {
    this.sharedService
      .checkUser(this.formGroup.value)
      .subscribe((response: any) => {
        this.sharedService.openSnackbar(response.message);
        if (!response.error) {
          this.formGroup.reset();
        }
      });
  }
}
