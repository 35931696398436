<div class="container-home">
  <!-- <a class="home" (click)="onMenu()" [routerLink]="['../child']">
    <img
      class="logo-img"
      src="/assets/logo.png"
      style="vertical-align: middle"
      alt="HOME"
    />
    home
  </a> -->
</div>
<div class="section legal-section">
  <div class="container">
    <div class="language">
      <label class="label" for="language">Language:</label>

      <select
        class="select"
        name="language"
        id="language"
        [(ngModel)]="language"
      >
        <option value="en">EN</option>
        <option value="de">DE</option>
      </select>
    </div>
    <div *ngIf="language === 'en'" fxLayout="column" fxLayoutGap="16px">
      <h1>Legal Notice</h1>

      <h2>Information pursuant to § 5 TMG</h2>
      <p>
        Antonio Maioriello<br />
        Columbusstraße 11<br />
        71686 Remseck
      </p>

      <h2>Contact</h2>
      <p>
        Phone: +4915771246217<br />
        E-mail: antonio.maioriello@ma-technology.de
      </p>

      <h2>Responsible for the content according to § 55(2) RStV</h2>
      <p>
        Antonio Maioriello<br />
        Columbusstraße 11<br />
        71686 Remseck<br />
        Ust.-ID: DE312332431
      </p>

      <h3>Liability for Contents</h3>
      <p>
        As service providers, we are liable for own contents of these websites
        according to Paragraph 7, Sect. 1 German Telemedia Act (TMG). However,
        according to Paragraphs 8 to 10 German Telemedia Act (TMG), service
        providers are not obligated to permanently monitor submitted or stored
        information or to search for evidences that indicate illegal activities.
      </p>
      <p>
        Legal obligations to removing information or to blocking the use of
        information remain unchallenged. In this case, liability is only
        possible at the time of knowledge about a specific violation of law.
        Illegal contents will be removed immediately at the time we get
        knowledge of them.
      </p>
      <h3>Liability for Links</h3>
      <p>
        Our offer includes links to external third party websites. We have no
        influence on the contents of those websites, therefore we cannot
        guarantee for those contents. Providers or administrators of linked
        websites are always responsible for their own contents.
      </p>
      <p>
        The linked websites had been checked for possible violations of law at
        the time of the establishment of the link. Illegal contents were not
        detected at the time of the linking. A permanent monitoring of the
        contents of linked websites cannot be imposed without reasonable
        indications that there has been a violation of law. Illegal links will
        be removed immediately at the time we get knowledge of them.
      </p>
      <h3>Copyright</h3>
      <p>
        Contents and compilations published on these websites by the providers
        are subject to German copyright laws. Reproduction, editing,
        distribution as well as the use of any kind outside the scope of the
        copyright law require a written permission of the author or originator.
        Downloads and copies of these websites are permitted for private use
        only.<br />
        The commercial use of our contents without permission of the originator
        is prohibited.
      </p>
      <p>
        Copyright laws of third parties are respected as long as the contents on
        these websites do not originate from the provider. Contributions of
        third parties on this site are indicated as such. However, if you notice
        any violations of copyright law, please inform us. Such contents will be
        removed immediately.
      </p>
    </div>
    <div *ngIf="language === 'de'" fxLayout="column" fxLayoutGap="16px">
      <h1>Impressum</h1>

      <h2>Angaben gemäß § 5 TMG</h2>
      <p>
        Antonio Maioriello<br />
        Columbusstraße 11<br />
        71686 Remseck
      </p>

      <h2>Kontakt</h2>
      <p>
        Phone: +4915771246217<br />
        E-mail: antonio.maioriello@ma-technology.de
      </p>

      <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
      <p>
        Antonio Maioriello<br />
        Columbusstraße 11<br />
        71686 Remseck<br />
        Ust.-ID: DE312332431
      </p>

      <h3>Haftung für Inhalte</h3>
      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
        diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
        bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
        übermittelte oder gespeicherte fremde Informationen zu überwachen oder
        nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
        hinweisen.
      </p>
      <p>
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
        Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen.
      </p>
      <h3>Haftung für Links</h3>
      <p>
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren
        Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar.
      </p>
      <p>
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
        ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
        Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
        entfernen.
      </p>
      <h3>Urheberrecht</h3>
      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
      </p>
      <p>
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
      </p>
    </div>
    <div class="backtohome">
      <a class="home" routerLink="/">
        <img
          class="logo-img-buttom"
          src="/assets/logo.png"
          style="vertical-align: middle"
          alt="HOME"
        />
        home
      </a>
    </div>
  </div>
</div>
