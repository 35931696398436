<nav class="nav">
  <div
    class="container desktop"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div class="mobile-nav-container" fxHide.gt-sm fxLayout="row">
      <div
        class="logo-container"
        fxHide.gt-sm
        fxLayout="column"
        fxLayoutGap="4px"
        fxLayoutAlign="center center"
      >
        <img
          (click)="scrollToAnchor('hero')"
          class="logo-img"
          alt="Logo"
          src="/assets/logo.png"
        />
      </div>
      <div
        class="menu-icon"
        (click)="onMenu()"
        fxHide.gt-sm
        fxLayout="column"
        fxLayoutGap="4px"
        fxLayoutAlign="center center"
      >
        <div *ngFor="let bar of [0, 1, 2]" class="bar"></div>
      </div>
    </div>

    <div
      *ngIf="isOpen || !isMobile"
      class="nav-items"
      fxLayout.gt-sm="column"
      fxLayout.lt-md="row"
      fxLayoutAlign.lt-md="center start"
      fxLayoutAlign.gt-sm="center center"
      fxLayoutGap.gt-sm="0px"
      fxLayoutGap.lt-md="40px"
    >
      <ul
        *ngIf="activeRoute === '/'"
        fxLayout.gt-sm="row"
        fxLayout.lt-md="column"
        fxLayoutGap="32px"
        class="anchor-items"
        @navItemsFadeInLeft
      >
        <li
          *ngFor="let section of sections"
          class="anchor-item"
          fxLayoutAlign="center center"
        >
          <a
            class="nav-link anchor-link"
            (click)="scrollToAnchor(section.anchor)"
            (click)="onMenu()"
            [routerLink]=""
            >{{ section?.name }}</a
          >
        </li>
      </ul>
      <ul
        *ngIf="activeRoute !== '/'"
        @navItemsFadeInLeft
        class="anchor-items"
        fxLayout.gt-sm="row"
        fxLayout.lt-md="column"
        fxLayoutGap="32px"
      >
        <li class="anchor-item" fxLayoutAlign="center center">
          <a class="nav-link anchor-link" (click)="onMenu()" routerLink="/"
            >home</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
<app-layer *ngIf="isOpen" (click)="onMenu()" fxHide.gt-sm></app-layer>
