<div class="container-home"></div>
<div class="section legal-section">
  <div class="container">
    <div>
      <h2 class="section-heading">User LogIn</h2>
      <form
        [formGroup]="formGroup"
        (submit)="onSubmit()"
        class="form contact-form"
      >
        <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutGap.gt-xs="32px">
          <div class="form-group">
            <label class="name" for="name">Name:</label>
            <input class="input" type="text" formControlName="name" id="name" />
          </div>
          <div class="form-group">
            <label class="password" for="password">Passwort:</label>
            <input
              class="input"
              type="password"
              formControlName="password"
              id="password"
            />
          </div>
        </div>
        <button
          [disabled]="formGroup.invalid"
          class="btn"
          type="submit"
          routerLink="/input-data"
        >
          Submit
        </button>
      </form>
    </div>

    <div class="backtohome">
      <a class="home" routerLink="/">
        <img
          class="logo-img-buttom"
          src="/assets/logo.png"
          style="vertical-align: middle"
          alt="HOME"
        />
        home
      </a>
    </div>
  </div>
</div>
