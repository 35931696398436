export const SOCIAL = [
  {
    name: 'LinkedIn',
    icon: 'linkedin.svg',
    link: 'https://www.linkedin.com/in/antonio-maioriello-247476221/',
  },
  {
    name: 'Xing',
    icon: 'xing.svg',
    link: 'https://www.xing.com/profile/Antonio_Maioriello/cv',
  },
];
