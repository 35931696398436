import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
})
export class LegalComponent implements OnInit {
  language = 'en';
  priceInEur: number;
  // isOpen? = false;

  constructor(private client: HttpClient) {}

  ngOnInit(): void {
    this.client
      .get('https://www.cryptocompare.com/cryptopian/api-keys')
      .subscribe((data) => {
        this.priceInEur = data['EUR'];
        console.log(data);
      });
  }

  // onMenu() {
  //   this.isOpen = true;
  // }
}
