export const CLIENTS = [
  { img: '/assets/img/clients/logo-volkswagen.png', name: '', id: 1 },
  { img: '/assets/img/clients/bosch.png', name: '', id: 2 },
  { img: '/assets/img/clients/logo-thyssenkrupp.png', name: '', id: 3 },
  { img: '/assets/img/clients/alfing.png', name: '', id: 4 },
  { img: '/assets/img/clients/logo-daimler.png', name: '', id: 5 },
  { img: '/assets/img/clients/elopro.png', name: '', id: 6 },
  { img: '/assets/img/clients/kiener.png', name: '', id: 7 },
  { img: '/assets/img/clients/volvo.png', name: '', id: 8 },
  { img: '/assets/img/clients/ma-automotive.png', name: '', id: 9 },
  { img: '/assets/img/clients/robologic.png', name: '', id: 10 },
  { img: '/assets/img/clients/werner-bayer.png', name: '', id: 11 },
  { img: '/assets/img/clients/maus.png', name: '', id: 12 },
];
