import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  mobile = false;
  constructor() {}

  ngOnInit(): void {}

  @HostListener('window:resize', [])
  onResize() {
    var width = window.innerWidth;
    this.mobile = width < 992;
  }
}
