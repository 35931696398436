<!-- <section class="section contact-section">
  <div class="container">
    <h2 class="section-heading">Kontakt</h2>
    <form
      [formGroup]="formGroup"
      (submit)="onSubmit()"
      class="form contact-form"
    >
      <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutGap.gt-xs="32px">
        <div class="form-group">
          <label class="label" for="name">Name</label>
          <input class="input" type="text" formControlName="name" id="name" />
        </div>
        <div class="form-group">
          <label class="label" for="email">E-Mail</label>
          <input
            class="input"
            type="email"
            formControlName="email"
            id="email"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="label" for="subject">Subject</label>
        <input
          class="input"
          type="text"
          formControlName="subject"
          id="subject"
        />
      </div>
      <div class="form-group">
        <label class="label" for="message">Message</label>
        <textarea
          class="textarea"
          type="text"
          formControlName="message"
          id="message"
        >
        </textarea>
      </div>
      <button [disabled]="formGroup.invalid" class="btn" type="submit">
        Submit
      </button>
    </form>
  </div>
</section> -->

<section
  class="section contact-section"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <h2 class="section-heading">Kontaktieren Sie uns</h2>

  <div class="container" fxLayout="row" fxLayoutAlign="center center">
    <div
      class="container-icons"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <img class="logo-img" alt="Logo" src="/assets/MA_Logo.png" />
      <img class="logo-robo" alt="Logo" src="/assets/roboter_grey.png" />
    </div>
    <div class="container-data" fxLayout="column">
      <div class="container-header">
        <h2 class="header-company">MA Automation Technology</h2>
      </div>

      <div class="container-info">
        <div fxLayout="column">
          <h3 class="name">Antonio Maioriello</h3>
          <a class="tel" href="tel: +4915771246217">
            <img
              class="img-phone"
              src="assets/icons/card/phone.svg"
              style="vertical-align: middle"
            />
            +4915771246217
          </a>

          <a class="email" href="mailto: antonio.maioriello@ma-technology.de"
            ><img
              class="img-email"
              src="assets/icons/card/email.svg"
              style="vertical-align: middle"
            />antonio.maioriello@ma-technology.de</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
