import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SectionsModule } from '../sections/sections.module';
import { SharedModule } from '../shared/shared.module';

import { HomePageComponent } from './home-page/home-page.component';
import { LegalComponent } from './legal/legal.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CvComponent } from './cv/cv.component';
import { LogoComponent } from '../components/logo/logo.component';
import { LoginComponent } from './login/login.component';
import { InputDataComponent } from './login/input-data/input-data.component';

@NgModule({
  declarations: [
    LogoComponent,
    HomePageComponent,
    LegalComponent,
    PrivacyComponent,
    CvComponent,
    LoginComponent,
    InputDataComponent,
  ],
  imports: [CommonModule, SharedModule, SectionsModule],
  exports: [
    HomePageComponent,
    LegalComponent,
    PrivacyComponent,
    CvComponent,
    LogoComponent,
    LoginComponent,
    InputDataComponent,
  ],
})
export class PagesModule {}
