<section class="section privacy-sectio">
  <div class="container">
    <div class="language">
      <label class="label" for="language">Language:</label>

      <select
        class="select"
        name="language"
        id="language"
        [(ngModel)]="language"
      >
        <option value="en">EN</option>
        <option value="de">DE</option>
      </select>
    </div>
    <div *ngIf="language === 'en'" fxLayout="column" fxLayoutGap="16px">
      <h1>Privacy Policy</h1>
      <h2>1. An overview of data protection</h2>
      <h3>General information</h3>
      <p>
        The following information will provide you with an easy to navigate
        overview of what will happen with your personal data when you visit this
        website. The term „personal data“ comprises all data that can be used to
        personally identify you. For detailed information about the subject
        matter of data protection, please consult our Data Protection
        Declaration, which we have included beneath this copy.
      </p>
      <h3>Data recording on this website</h3>
      <p>
        <strong
          >Who is the responsible party for the recording of data on this
          website (i.e. the „controller“)?</strong
        >
      </p>
      <p>
        The data on this website is processed by the operator of the website,
        whose contact information is available under section „Legal Notice“ on
        this website.
      </p>
      <p><strong>How do we record your data?</strong></p>
      <p>
        We collect your data as a result of your sharing of your data with us.
        This may, for instance be information you enter into our contact form.
      </p>
      <p>
        Our IT systems automatically record other data when you visit our
        website. This data comprises primarily technical information (e.g. web
        browser, operating system or time the site was accessed). This
        information is recorded automatically when you access this website.
      </p>
      <p><strong>What are the purposes we use your data for?</strong></p>
      <p>
        A portion of the information is generated to guarantee the error free
        provision of the website. Other data may be used to analyse your user
        patterns.
      </p>
      <p>
        <strong
          >What rights do you have as far as your information is
          concerned?</strong
        >
      </p>
      <p>
        You have the right to receive information about the source, recipients
        and purposes of your archived personal data at any time without having
        to pay a fee for such disclosures. You also have the right to demand
        that your data are rectified or eradicated. Please do not hesitate to
        contact us at any time under the address disclosed in section „Legal
        Notice“ on this website if you have questions about this or any other
        data protection related issues. You also have the right to log a
        complaint with the competent supervising agency.
      </p>
      <p>
        Moreover, under certain circumstances, you have the right to demand the
        restriction of the processing of your personal data. For details, please
        consult the Data Protection Declaration under section „Right to
        Restriction of Data Processing.“
      </p>
      <h3>Analysis tools and tools provided by third parties</h3>
      <p>
        There is a possibility that your browsing patterns will be statistically
        analysed when your visit this website. Such analyses are performed
        primarily with cookies and with what we refer to as analysis programmes.
        As a rule, the analyses of your browsing patterns are conducted
        anonymously; i.e. the browsing patterns cannot be traced back to you.
      </p>
      <p>
        You have the option to object to such analyses or you can prevent their
        performance by not using certain tools. For detailed information about
        the tools and about your options to object, please consult our Data
        Protection Declaration below.
      </p>
      <h2>2. General information and mandatory information</h2>
      <h3>Data protection</h3>
      <p>
        The operators of this website and its pages take the protection of your
        personal data very seriously. Hence, we handle your personal data as
        confidential information and in compliance with the statutory data
        protection regulations and this Data Protection Declaration.
      </p>
      <p>
        Whenever you use this website, a variety of personal information will be
        collected. Personal data comprises data that can be used to personally
        identify you. This Data Protection Declaration explains which data we
        collect as well as the purposes we use this data for. It also explains
        how, and for which purpose the information is collected.
      </p>
      <p>
        We herewith advise you that the transmission of data via the Internet
        (i.e. through e-mail communications) may be prone to security gaps. It
        is not possible to completely protect data against third party access.
      </p>
      <h3>
        Information about the responsible party (referred to as the „controller“
        in the GDPR)
      </h3>
      <p>The data processing controller on this website is:</p>
      <p>
        Antonio Maioriello<br />
        Columbusstraße 11<br />
        71686 Remseck
      </p>

      <p>
        Phone: +4915771246217<br />
        E-mail: antonio.maioriello@ma-technology.de
      </p>
      <p>
        The controller is the natural person or legal entity that
        single-handedly or jointly with others makes decisions as to the
        purposes of and resources for the processing of personal data (e.g.
        names, e-mail addresses, etc.).
      </p>
      <h3>Revocation of your consent to the processing of data</h3>
      <p>
        A wide range of data processing transactions are possible only subject
        to your express consent. You can also revoke at any time any consent you
        have already given us. To do so, all you are required to do is sent us
        an informal notification via e-mail. This shall be without prejudice to
        the lawfulness of any data collection that occurred prior to your
        revocation.
      </p>
      <h3>
        Right to object to the collection of data in special cases; right to
        object to direct advertising (Art. 21 GDPR)
      </h3>
      <p>
        IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6 SECT. 1 LIT.
        E OR F GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING
        OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE
        SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS.
        TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED,
        PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN
        OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS
        WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR
        THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND
        FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING
        OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21 SECT. 1
        GDPR).
      </p>
      <p>
        IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT
        ADVERTISING, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING
        OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING.
        THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH
        SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL
        SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES
        (OBJECTION PURSUANT TO ART. 21 SECT. 2 GDPR).
      </p>
      <h3>Right to log a complaint with the competent supervisory agency</h3>
      <p>
        In the event of violations of the GDPR, data subjects are entitled to
        log a complaint with a supervisory agency, in particular in the member
        state where they usually maintain their domicile, place of work or at
        the place where the alleged violation occurred. The right to log a
        complaint is in effect regardless of any other administrative or court
        proceedings available as legal recourses.
      </p>
      <h3>Right to data portability</h3>
      <p>
        You have the right to demand that we hand over any data we automatically
        process on the basis of your consent or in order to fulfil a contract be
        handed over to you or a third party in a commonly used, machine readable
        format. If you should demand the direct transfer of the data to another
        controller, this will be done only if it is technically feasible.
      </p>
      <h3>SSL and/or TLS encryption</h3>
      <p>
        For security reasons and to protect the transmission of confidential
        content, such as purchase orders or inquiries you submit to us as the
        website operator, this website uses either an SSL or a TLS encryption
        programme. You can recognise an encrypted connection by checking whether
        the address line of the browser switches from „http://“ to „https://“
        and also by the appearance of the lock icon in the browser line.
      </p>
      <p>
        If the SSL or TLS encryption is activated, data you transmit to us
        cannot be read by third parties.
      </p>
      <h3>Encrypted payment transactions on this website</h3>
      <p>
        If you are under an obligation to share your payment information (e.g.
        account number if you give us the authority to debit your bank account)
        with us after you have entered into a fee-based contract with us, this
        information is required to process payments.
      </p>
      <p>
        Payment transactions using common modes of paying (Visa/MasterCard,
        debit to your bank account) are processed exclusively via encrypted SSL
        or TLS connections. You can recognise an encrypted connection by
        checking whether the address line of the browser switches from „http://“
        to „https://“ and also by the appearance of the lock icon in the browser
        line.
      </p>
      <p>
        If the communication with us is encrypted, third parties will not be
        able to read the payment information you share with us.
      </p>
      <h3>Information about, rectification and eradication of data</h3>
      <p>
        Within the scope of the applicable statutory provisions, you have the
        right to at any time demand information about your archived personal
        data, their source and recipients as well as the purpose of the
        processing of your data. You may also have a right to have your data
        rectified or eradicated. If you have questions about this subject matter
        or any other questions about personal data, please do not hesitate to
        contact us at any time at the address provided in section „Legal
        Notice.“
      </p>
      <h3>Right to demand processing restrictions</h3>
      <p>
        You have the right to demand the imposition of restrictions as far as
        the processing of your personal data is concerned. To do so, you may
        contact us at any time at the address provided in section „Legal
        Notice.“ The right to demand restriction of processing applies in the
        following cases:
      </p>
      <ul>
        <li>
          In the event that you should dispute the correctness of your data
          archived by us, we will usually need some time to verify this claim.
          During the time that this investigation is ongoing, you have the right
          to demand that we restrict the processing of your personal data.
        </li>
        <li>
          If the processing of your personal data was/is conducted in an
          unlawful manner, you have the option to demand the restriction of the
          processing of your data in lieu of demanding the eradication of this
          data.
        </li>
        <li>
          If we do not need your personal data any longer and you need it to
          exercise, defend or claim legal entitlements, you have the right to
          demand the restriction of the processing of your personal data instead
          of its eradication.
        </li>
        <li>
          If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR, your
          rights and our rights will have to be weighed against each other. As
          long as it has not been determined whose interests prevail, you have
          the right to demand a restriction of the processing of your personal
          data.
        </li>
      </ul>
      <p>
        If you have restricted the processing of your personal data, these data
        – with the exception of their archiving – may be processed only subject
        to your consent or to claim, exercise or defend legal entitlements or to
        protect the rights of other natural persons or legal entities or for
        important public interest reasons cited by the European Union or a
        member state of the EU.
      </p>
      <h3>Rejection of unsolicited e-mails</h3>
      <p>
        We herewith object to the use of contact information published in
        conjunction with the mandatory information to be provided in section
        „Legal Notice“ to send us promotional and information material that we
        have not expressly requested. The operators of this website and its
        pages reserve the express right to take legal action in the event of the
        unsolicited sending of promotional information, for instance via SPAM
        messages.
      </p>
      <h2>3. Recording of data on this website</h2>
      <h3>Cookies</h3>
      <p>
        In some instances, our website and its pages use so-called cookies.
        Cookies do not cause any damage to your computer and do not contain
        viruses. The purpose of cookies is to make our website more user
        friendly, effective and more secure. Cookies are small text files that
        are placed on your computer and stored by your browser.
      </p>
      <p>
        Most of the cookies we use are so-called „session cookies.“ They are
        automatically deleted after your leave our site. Other cookies will
        remain archived on your device until you delete them. These cookies
        enable us to recognise your browser the next time you visit our website.
      </p>
      <p>
        You can adjust the settings of your browser to make sure that you are
        notified every time cookies are placed and to enable you to accept
        cookies only in specific cases or to exclude the acceptance of cookies
        for specific situations or in general and to activate the automatic
        deletion of cookies when you close your browser. If you deactivate
        cookies, the functions of this website may be limited.
      </p>
      <p>
        Cookies that are required for the performance of the electronic
        communications transaction or to provide certain functions you want to
        use (e.g. the shopping cart function), are stored on the basis of Art. 6
        Sect. 1 lit. f GDPR. The website operator has a legitimate interest in
        storing cookies to ensure the technically error free and optimised
        provision of the operator’s services. If a corresponding agreement has
        been requested (e.g. an agreement to the storage of cookies), the
        processing takes place exclusively on the basis of Art. 6 para. 1 lit. a
        GDPR; the agreement can be revoked at any time.
      </p>
      <p>
        If other cookies (e.g. cookies for the analysis of your browsing
        patterns) should be stored, they are addressed separately in this Data
        Protection Declaration.
      </p>
      <h3>Server log files</h3>
      <p>
        The provider of this website and its pages automatically collects and
        stores information in so-called server log files, which your browser
        communicates to us automatically. The information comprises:
      </p>
      <ul>
        <li>The type and version of browser used</li>
        <li>The used operating system</li>
        <li>Referrer URL</li>
        <li>The hostname of the accessing computer</li>
        <li>The time of the server inquiry</li>
        <li>The IP address</li>
      </ul>
      <p>This data is not merged with other data sources.</p>
      <p>
        This data is recorded on the basis of Art. 6 Sect. 1 lit. f GDPR. The
        operator of the website has a legitimate interest in the technically
        error free depiction and the optimization of the operator’s website. In
        order to achieve this, server log files must be recorded.
      </p>
      <h3>Contact form</h3>
      <p>
        If you submit inquiries to us via our contact form, the information
        provided in the contact form as well as any contact information provided
        therein will be stored by us in order to handle your inquiry and in the
        event that we have further questions. We will not share this information
        without your consent.
      </p>
      <p>
        The processing of these data is based on Art. 6 para. 1 lit. b GDPR, if
        your request is related to the execution of a contract or if it is
        necessary to carry out pre-contractual measures. In all other cases the
        processing is based on our legitimate interest in the effective
        processing of the requests addressed to us (Art. 6 Para. 1 lit. f DSGVO)
        or on your agreement (Art. 6 Para. 1 lit. a DSGVO) if this has been
        requested.
      </p>
      <p>
        The information you have entered into the contact form shall remain with
        us until you ask us to eradicate the data, revoke your consent to the
        archiving of data or if the purpose for which the information is being
        archived no longer exists (e.g. after we have concluded our response to
        your inquiry). This shall be without prejudice to any mandatory legal
        provisions – in particular retention periods.
      </p>
      <h3>Request by e-mail, telephone or fax</h3>
      <p>
        If you contact us by e-mail, telephone or fax, your request, including
        all resulting personal data (name, request) will be stored and processed
        by us for the purpose of processing your request. We do not pass these
        data on without your consent.
      </p>
      <p>
        The processing of these data is based on Art. 6 para. 1 lit. b GDPR, if
        your request is related to the execution of a contract or if it is
        necessary to carry out pre-contractual measures. In all other cases, the
        processing is based on your consent (Article 6 (1) a GDPR) and/or on our
        legitimate interests (Article 6 (1) (f) GDPR), since we have a
        legitimate interest in the effective processing of requests addressed to
        us.
      </p>
      <p>
        The data sent by you to us via contact requests remain with us until you
        request us to delete, revoke your consent to the storage or the purpose
        for the data storage lapses (e.g. after completion of your request).
        Mandatory statutory provisions - in particular statutory retention
        periods - remain unaffected.
      </p>
      <h3>Registration on this website</h3>
      <p>
        You have the option to register on this website to be able to use
        additional website functions. We shall use the data you enter only for
        the purpose of using the respective offer or service you have registered
        for. The required information we request at the time of registration
        must be entered in full. Otherwise we shall reject the registration.
      </p>
      <p>
        To notify you of any important changes to the scope of our portfolio or
        in the event of technical modifications, we shall use the e-mail address
        provided during the registration process.
      </p>
      <p>
        We shall process the data entered during the registration process on the
        basis of your consent (Art. 6 Sect. 1 lit. a GDPR).
      </p>
      <p>
        The data recorded during the registration process shall be stored by us
        as long as you are registered on this website. Subsequently, such data
        shall be deleted. This shall be without prejudice to mandatory statutory
        retention obligations.
      </p>
      <h3>The comment function on this website</h3>
      <p>
        When you use the comment function on this website, information on the
        time the comment was generated and your e-mail-address and, if you are
        not posting anonymously, the user name you have selected will be
        archived in addition to your comments.
      </p>
      <p><strong>Storage of the IP address</strong></p>
      <p>
        Our comment function stores the IP addresses of all users who enter
        comments. Given that we do not review the comments prior to publishing
        them, we need this information in order to take action against the
        author in the event of rights violations, such as defamation or
        propaganda.
      </p>
      <p><strong>Subscribing to comments</strong></p>
      <p>
        As a user of this website, you have the option to subscribe to comments
        after you have registered. You will receive a confirmation e-mail, the
        purpose of which is to verify whether you are the actual holder of the
        provided e-mail address. You can deactivate this function at any time by
        following a respective link in the information e-mails. The data entered
        in conjunction with subscriptions to comments will be deleted in this
        case. However, if you have communicated this information to us for other
        purposes and from a different location (e.g. when subscribing to the
        newsletter), the data shall remain in our possession.
      </p>
      <p><strong>Storage period for comments</strong></p>
      <p>
        Comments and any affiliated information (e.g. the IP address) shall be
        stored by us and remain on this website until the content the comment
        pertained to has been deleted in its entirety or if the comments had to
        be deleted for legal reasons (e.g. insulting comments).
      </p>
      <p><strong>Legal basis</strong></p>
      <p>
        Comments are stored on the basis of your consent (Art. 6 Sect. 1 lit. a
        GDPR). You have the right to revoke at any time any consent you have
        already given us. To do so, all you are required to do is sent us an
        informal notification via e-mail. This shall be without prejudice to the
        lawfulness of any data collection that occurred prior to your
        revocation.
      </p>
      <h3>Processing of data (customer and contract data)</h3>
      <p>
        We collect, process and use personal data only to the extent necessary
        for the establishment, content organization or change of the legal
        relationship (data inventory). These actions are taken on the basis of
        Art. 6 Sect. 1 lit. b GDPR, which permits the processing of data for the
        fulfilment of a contract or pre-contractual actions. We collect, process
        and use personal data concerning the use of this website (usage data)
        only to the extent that this is necessary to make it possible for users
        to utilize the services and to bill for them.
      </p>
      <p>
        The collected customer data shall be eradicated upon completion of the
        order or the termination of the business relationship. This shall be
        without prejudice to any statutory retention mandates.
      </p>
      <h3>
        Data transfer upon closing of contracts for online stores, retailers and
        the shipment of merchandise
      </h3>
      <p>
        We share personal data with third parties only if this is necessary in
        conjunction with the handling of the contract; for instance, with
        companies entrusted with the shipment of goods or the financial
        institution tasked with the processing of payments. Any further transfer
        of data shall not occur or shall only occur if you have expressly
        consented to the transfer. Any sharing of your data with third parties
        in the absence of your express consent, for instance for advertising
        purposes, shall not occur.
      </p>
      <p>
        The basis for the processing of data is Art. 6 Sect. 1 lit. b GDPR,
        which permits the processing of data for the fulfilment of a contract or
        for pre-contractual actions.
      </p>
      <h3>
        Data transfer upon closing of contracts for services and digital content
      </h3>
      <p>
        We share personal data with third parties only if this is necessary in
        conjunction with the handling of the contract; for instance, with the
        financial institution tasked with the processing of payments.
      </p>
      <p>
        Any further transfer of data shall not occur or shall only occur if you
        have expressly consented to the transfer. Any sharing of your data with
        third parties in the absence of your express consent, for instance for
        advertising purposes, shall not occur.
      </p>
      <p>
        The basis for the processing of data is Art. 6 Sect. 1 lit. b GDPR,
        which permits the processing of data for the fulfilment of a contract or
        for pre-contractual actions.
      </p>
      <h2>4. Social media</h2>
      <h3>Twitter plug-in</h3>
      <p>
        We have integrated functions of the social media platform Twitter into
        this website. These functions are provided by Twitter Inc., 1355 Market
        Street, Suite 900, San Francisco, CA 94103, USA. While you use Twitter
        and the „Re-Tweet“ function, websites you visit are linked to your
        Twitter account and disclosed to other users. During this process, data
        are transferred to Twitter as well. We must point out, that we, the
        providers of the website and its pages do not know anything about the
        content of the data transferred and the use of this information by
        Twitter. For more details, please consult Twitter’s Data Privacy
        Declaration at:
        <a
          href="https://twitter.com/en/privacy"
          target="_blank"
          rel="noopener noreferrer"
          >https://twitter.com/en/privacy</a
        >.
      </p>
      <p>
        The use of Twitter plug-ins is based on Art. 6 Sect. 1 lit. f GDPR. The
        operator of the website has a legitimate interest in being as visible as
        possible on social media.
      </p>
      <p>
        You have the option to reset your data protection settings on Twitter
        under the account settings at
        <a
          href="https://twitter.com/account/settings"
          target="_blank"
          rel="noopener noreferrer"
          >https://twitter.com/account/settings</a
        >.
      </p>
      <h3>Instagram plug-in</h3>
      <p>
        We have integrated functions of the public media platform Instagram into
        this website. These functions are being offered by Instagram Inc., 1601
        Willow Road, Menlo Park, CA 94025, USA.
      </p>
      <p>
        If you are logged into your Instagram account, you may click the
        Instagram button to link contents from this website to your Instagram
        profile. This enables Instagram to allocate your visit to this website
        to your user account. We have to point out that we as the provider of
        the website and its pages do not have any knowledge of the content of
        the data transferred and its use by Instagram.
      </p>
      <p>
        The use of the Instagram plug-in is based on Art. 6 Sect. 1 lit. f GDPR.
        The operator of the website has a legitimate interest in being as
        visible as possible on social media.
      </p>
      <p>
        For more information on this subject, please consult Instagram’s Data
        Privacy Declaration at:
        <a
          href="https://instagram.com/about/legal/privacy/"
          target="_blank"
          rel="noopener noreferrer"
          >https://instagram.com/about/legal/privacy/</a
        >.
      </p>
      <h2>5. Analysis tools and advertising</h2>
      <h3>Google Analytics</h3>
      <p>
        This website uses functions of the web analysis service Google
        Analytics. The provider of this service is Google Ireland Limited
        („Google“), Gordon House, Barrow Street, Dublin 4, Ireland.
      </p>
      <p>
        Google Analytics uses so-called cookies. Cookies are text files, which
        are stored on your computer and that enable an analysis of the use of
        the website by users. The information generated by cookies on your use
        of this website is usually transferred to a Google server in the United
        States, where it is stored.
      </p>
      <p>
        The storage of Google Analytics cookies and the utilization of this
        analysis tool are based on Art. 6 Sect. 1 lit. f GDPR. The operator of
        this website has a legitimate interest in the analysis of user patterns
        to optimize both, the services offered online and the operator’s
        advertising activities. If a corresponding agreement has been requested
        (e.g. an agreement to the storage of cookies), the processing takes
        place exclusively on the basis of Art. 6 para. 1 lit. a GDPR; the
        agreement can be revoked at any time.
      </p>
      <p><strong>IP anonymization</strong></p>
      <p>
        On this website, we have activated the IP anonymization function. As a
        result, your IP address will be abbreviated by Google within the member
        states of the European Union or in other states that have ratified the
        Convention on the European Economic Area prior to its transmission to
        the United States. The full IP address will be transmitted to one of
        Google’s servers in the United States and abbreviated there only in
        exceptional cases. On behalf of the operator of this website, Google
        shall use this information to analyse your use of this website to
        generate reports on website activities and to render other services to
        the operator of this website that are related to the use of the website
        and the Internet. The IP address transmitted in conjunction with Google
        Analytics from your browser shall not be merged with other data in
        Google’s possession.
      </p>
      <p><strong>Browser plug-in</strong></p>
      <p>
        You do have the option to prevent the archiving of cookies by making
        pertinent changes to the settings of your browser software. However, we
        have to point out that in this case you may not be able to use all of
        the functions of this website to their fullest extent. Moreover, you
        have the option prevent the recording of the data generated by the
        cookie and affiliated with your use of the website (including your IP
        address) by Google as well as the processing of this data by Google by
        downloading and installing the browser plug-in available under the
        following link:
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://tools.google.com/dlpage/gaoptout?hl=en</a
        >.
      </p>
      <p><strong>Objection to the recording of data</strong></p>
      <p>
        You have the option to prevent the recording of your data by Google
        Analytics by clicking on the following link. This will result in the
        placement of an opt out cookie, which prevents the recording of your
        data during future visits to this website:
        <a href="javascript:gaOptout();">Google Analytics deactivation</a>.
      </p>
      <p>
        For more information about the handling of user data by Google
        Analytics, please consult Google’s Data Privacy Declaration at:
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://support.google.com/analytics/answer/6004245?hl=en</a
        >.
      </p>
      <p><strong>Contract data processing</strong></p>
      <p>
        We have executed a contract data processing agreement with Google and
        are implementing the stringent provisions of the German data protection
        agencies to the fullest when using Google Analytics.
      </p>
      <p>
        <strong>Demographic parameters provided by Google Analytics</strong>
      </p>
      <p>
        This website uses the function „demographic parameters“ provided by
        Google Analytics. It makes it possible to generate reports providing
        information on the age, gender and interests of website visitors. The
        sources of this information are interest-related advertising by Google
        as well as visitor data obtained from third party providers. This data
        cannot be allocated to a specific individual. You have the option to
        deactivate this function at any time by making pertinent settings
        changes for advertising in your Google account or you can generally
        prohibit the recording of your data by Google Analytics as explained in
        section „Objection to the recording of data.“
      </p>
      <p><strong>Archiving period</strong></p>
      <p>
        Data on the user or incident level stored by Google linked to cookies,
        user IDs or advertising IDs (e.g. DoubleClick cookies, Android
        advertising ID) will be anonymized or deleted after 14 month. For
        details please click the following link:
        <a
          href="https://support.google.com/analytics/answer/7667196?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://support.google.com/analytics/answer/7667196?hl=en</a
        >
      </p>

      <h3>Google AdSense</h3>
      <p>
        This website uses Google AdSense, a service for the integration of ads.
        The provider of this service is Google Ireland Limited („Google“),
        Gordon House, Barrow Street, Dublin 4, Ireland.
      </p>
      <p>
        Google AdSense uses so-called „cookies“; i.e. text files that are stored
        on your computer and that make it possible to analyse your use of this
        website. Google AdSense also uses so-called Web Beacons (invisible
        graphics). These Web Beacons can be used to analyse information, such as
        visitor traffic data, on this website.
      </p>
      <p>
        The information generated by the cookies and the Web Beacons concerning
        the use of this website (including your IP address) and the delivery of
        advertising formats are transferred to a Google server in the United
        States, where they are archived. Google may share this information with
        Google contracting partners. Google shall, however, not merge your IP
        address with any other of your data stored by Google.
      </p>
      <p>
        The storage of the AdSense cookies is based on Art. 6 Sect. 1 lit. f
        GDPR. The website operator has a legitimate interest in the analysis of
        user patterns, in order to optimize the operator’s web offerings and
        advertising.
      </p>
      <p>
        You can prevent the installation of these cookies by making a pertinent
        adjustment to your browser software settings; however, we have to point
        out that in this case you may not be able to use all of the functions of
        this website to their fullest extent. By using this website, you declare
        your consent to the processing of the data we collect about you by
        Google in the manner described above and agree to the aforementioned
        purpose of use.
      </p>
      <h3>Google Analytics Remarketing</h3>
      <p>
        This website uses the functions of Google Analytics Remarketing in
        combination with the functions of Google AdWords and Google DoubleClick,
        which work on all devices. The provider of these solutions is Google
        Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4,
        Ireland.
      </p>
      <p>
        This function makes it possible to connect the advertising target groups
        generated with Google Analytics Remarketing with the functions of Google
        AdWords and Google DoubleClick, which work on all devices. This makes it
        possible to display interest-based customized advertising messages,
        depending on your prior usage and browsing patterns on a device (e.g.
        cell phone) in a manner tailored to you as well as on any of your
        devices (e.g. tablet or PC).
      </p>
      <p>
        If you have given us pertinent consent, Google will connect your web and
        app browser progressions with your Google account for this purpose. As a
        result, it is possible to display the same personalized advertising
        messages on every device you log into with your Google account.
      </p>
      <p>
        To support this function, Google Analytics records Google authenticated
        IDs of users that are temporarily connected with our Google Analytics
        data to define and compile the target groups for the ads to be displayed
        on all devices.
      </p>
      <p>
        You have the option to permanently object to remarketing/targeting
        across all devices by deactivating personalized advertising. To do this,
        please follow this link:
        <a
          href="https://www.google.com/settings/ads/onweb/"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.google.com/settings/ads/onweb/</a
        >.
      </p>
      <p>
        The consolidation of the recorded data in your Google account shall
        occur exclusively based on your consent, which you may give to Google
        and also revoke there (Art. 6 Sect. 1 lit. a GDPR). Data recording
        processes that are not consolidated in your Google account (for instance
        because you do not have a Google account or have objected to the
        consolidation of data), the recording of data is based on Art. 6 Sect. 1
        lit. f GDPR. The legitimate interest arises from the fact that the
        operator of the website has a legitimate interest in the anonymized
        analysis of website visitor for advertising purposes.
      </p>
      <p>
        For further information and the pertinent data protection regulations,
        please consult the Data Privacy Policies of Google at:
        <a
          href="https://policies.google.com/technologies/ads?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/technologies/ads?hl=en</a
        >.
      </p>
      <h3>Google AdWords and Google Conversion Tracking</h3>
      <p>
        This website uses Google AdWords. AdWords is an online promotional
        program of Google Ireland Limited („Google“), Gordon House, Barrow
        Street, Dublin 4, Ireland.
      </p>
      <p>
        In conjunction with Google AdWords, we use a tool called Conversion
        Tracking. If you click on an ad posted by Google, a cookie for
        Conversion Tracking purposes will be placed. Cookies are small text
        files the web browser places on the user’s computer. These cookies
        expire after 30 days and are not used to personally identify users. If
        the user visits certain pages of this website and the cookie has not yet
        expired, Google and we will be able to recognise that the user has
        clicked on an ad and has been linked to this page.
      </p>
      <p>
        A different cookie is allocated to every Google AdWords customer. These
        cookies cannot be tracked via websites of AdWords customers. The
        information obtained with the assistance of the Conversion cookie is
        used to generate Conversion statistics for AdWords customers who have
        opted to use Conversion Tracking. The users receive the total number of
        users that have clicked on their ads and have been linked to a page
        equipped with a Conversion Tracking tag. However, they do not receive
        any information that would allow them to personally identify these
        users. If you do not want to participate in tracking, you have the
        option to object to this use by easily deactivating the Google
        Conversion Tracking cookie via your web browser under user settings. If
        you do this, you will not be included in the Conversion Tracking
        statistics.
      </p>
      <p>
        The storage of „Conversion“ cookies and the use of this tracking tool
        are based on Art. 6 Sect. 1 lit. f GDPR. The website operator has a
        legitimate interest in the analysis of user patterns, in order to
        optimize the operator’s web offerings and advertising. If a
        corresponding agreement has been requested (e.g. an agreement to the
        storage of cookies), the processing takes place exclusively on the basis
        of Art. 6 para. 1 lit. a GDPR; the agreement can be revoked at any time.
      </p>
      <p>
        To review more detailed information about Google AdWords and Google
        Conversion Tracking, please consult the Data Privacy Policies of Google
        at:
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=en</a
        >.
      </p>
      <p>
        You can set up your browser in such a manner that you will be notified
        anytime cookies are placed and you can permit cookies only in certain
        cases or exclude the acceptance of cookies in certain instances or in
        general and you can also activate the automatic deletion of cookies upon
        closing of the browser. If you deactivate cookies, the functions of this
        website may be limited.
      </p>
      <h3>Google DoubleClick</h3>
      <p>
        This website uses features of Google DoubleClick. The provider is Google
        LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA,
        (hereinafter „DoubleClick“).
      </p>
      <p>
        DoubleClick is used to show you interest-based ads across the Google
        Network. Advertisements can be tailored to the interests of the viewer
        using DoubleClick.  For example, our ads may appear in Google search
        results or in banners associated with DoubleClick.
      </p>
      <p>
        DoubleClick must be able to recognize the respective viewer in order to
        be able to display interest-based advertising to users. For this
        purpose, a cookie is stored in the user's browser, behind which the
        websites visited by the user, clicks and various other information are
        stored. This information is aggregated into a pseudonymous user profile
        to display interest-based advertising to the user concerned.
      </p>
      <p>
        The use of Google DoubleClick takes place in the interest of targeted
        advertising measures. This constitutes a legitimate interest within the
        meaning of Art. 6 para. 1 lit. f GDPR. If a corresponding agreement has
        been requested (e.g. an agreement to the storage of cookies), the
        processing takes place exclusively on the basis of Art. 6 para. 1 lit. a
        GDPR; the agreement can be revoked at any time.
      </p>
      <p>
        You can set your browser so that it no longer stores cookies. However,
        this can restrict the accessible functions of websites. It should also
        be noted that DoubleClick may also use other technologies to create user
        profiles. Disabling cookies therefore does not guarantee that user
        profiles will no longer be created.
      </p>
      <p>
        For further information on how to object to the advertisements displayed
        by Google, please see the following links:
        <a
          href="https://policies.google.com/technologies/ads"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/technologies/ads</a
        >
        and
        <a
          href="https://adssettings.google.com/authenticated"
          target="_blank"
          rel="noopener noreferrer"
          >https://adssettings.google.com/authenticated</a
        >.
      </p>
      <h2>6. Newsletter</h2>
      <h3>Newsletter data</h3>
      <p>
        If you would like to subscribe to the newsletter offered on this
        website, we will need from you an e-mail address as well as information
        that allow us to verify that you are the owner of the e-mail address
        provided and consent to the receipt of the newsletter. No further data
        shall be collected or shall be collected only on a voluntary basis. We
        shall use such data only for the sending of the requested information
        and shall not share such data with any third parties.
      </p>
      <p>
        The processing of the information entered into the newsletter
        subscription form shall occur exclusively on the basis of your consent
        (Art. 6 Sect. 1 lit. a GDPR). You may revoke the consent you have given
        to the archiving of data, the e-mail address and the use of this
        information for the sending of the newsletter at any time, for instance
        by clicking on the „Unsubscribe“ link in the newsletter. This shall be
        without prejudice to the lawfulness of any data processing transactions
        that have taken place to date.
      </p>
      <p>
        The data deposited with us for the purpose of subscribing to the
        newsletter will be stored by us until you unsubscribe from the
        newsletter or the newsletter service provider and deleted from the
        newsletter distribution list after you unsubscribe from the newsletter.
        Data stored for other purposes with us remain unaffected.
      </p>
      <p>
        After you unsubscribe from the newsletter distribution list, your e-mail
        address may be stored by us or the newsletter service provider in a
        blacklist to prevent future mailings. The data from the blacklist is
        used only for this purpose and not merged with other data. This serves
        both your interest and our interest in complying with the legal
        requirements when sending newsletters (legitimate interest within the
        meaning of Art. 6 para. 1 lit. f GDPR). The storage in the blacklist is
        indefinite.
        <strong
          >You may object to the storage if your interests outweigh our
          legitimate interest.</strong
        >
      </p>
      <h2>7. Plug-ins and Tools</h2>
      <h3>YouTube with expanded data protection integration</h3>
      <p>
        Our website embeds videos of the website YouTube. The website operator
        is Google Ireland Limited („Google“), Gordon House, Barrow Street,
        Dublin 4, Ireland.
      </p>
      <p>
        We use YouTube in the expanded data protection mode. According to
        YouTube, this mode ensures that YouTube does not store any information
        about visitors to this website before they watch the video.
        Nevertheless, this does not necessarily mean that the sharing of data
        with YouTube partners can be ruled out as a result of the expanded data
        protection mode. For instance, regardless of whether you are watching a
        video, YouTube will always establish a connection with the Google
        DoubleClick network.
      </p>
      <p>
        As soon as you start to play a YouTube video on this website, a
        connection to YouTube’s servers will be established. As a result, the
        YouTube server will be notified, which of our pages you have visited. If
        you are logged into your YouTube account while you visit our site, you
        enable YouTube to directly allocate your browsing patterns to your
        personal profile. You have the option to prevent this by logging out of
        your YouTube account.
      </p>
      <p>
        Furthermore, after you have started to play a video, YouTube will be
        able to place various cookies on your device. With the assistance of
        these cookies, YouTube will be able to obtain information about our
        website's visitors. Among other things, this information will be used to
        generate video statistics with the aim of improving the user
        friendliness of the site and to prevent attempts to commit fraud. These
        cookies will stay on your device until you delete them.
      </p>
      <p>
        Under certain circumstances, additional data processing transactions may
        be triggered after you have started to play a YouTube video, which are
        beyond our control.
      </p>
      <p>
        The use of YouTube is based on our interest in presenting our online
        content in an appealing manner. Pursuant to Art. 6 Sect. 1 lit. f GDPR,
        this is a legitimate interest. If a corresponding agreement has been
        requested (e.g. an agreement to the storage of cookies), the processing
        takes place exclusively on the basis of Art. 6 para. 1 lit. a GDPR; the
        agreement can be revoked at any time.
      </p>
      <p>
        For more information on how YouTube handles user data, please consult
        the YouTube Data Privacy Policy under:
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=en</a
        >.
      </p>
      <h3>Vimeo</h3>
      <p>
        This website uses plug-ins of the video portal Vimeo. The provider is
        Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.
      </p>
      <p>
        If you visit one of the pages on our website into which a Vimeo plug-in
        has been integrated, a connection to Vimeo’s servers will be
        established. As a consequence, the Vimeo server will receive information
        as to which of our pages you have visited. Moreover, Vimeo will receive
        your IP address. This will also happen if you are not logged into Vimeo
        or do not have an account with Vimeo. The information recorded by Vimeo
        will be transmitted to Vimeo’s server in the United States.
      </p>
      <p>
        If you are logged into your Vimeo account, you enable Vimeo to directly
        allocate your browsing patterns to your personal profile. You can
        prevent this by logging out of your Vimeo account.
      </p>
      <p>
        The use of Vimeo is based on our interest in presenting our online
        content in an appealing manner. Pursuant to Art. 6 Sect. 1 lit. f GDPR,
        this is a legitimate interest. If a corresponding agreement has been
        requested (e.g. an agreement to the storage of cookies), the processing
        takes place exclusively on the basis of Art. 6 para. 1 lit. a GDPR; the
        agreement can be revoked at any time.
      </p>
      <p>
        For more information on how Vimeo handles user data, please consult the
        Vimeo Data Privacy Policy under:
        <a
          href="https://vimeo.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          >https://vimeo.com/privacy</a
        >.
      </p>
      <h3>Google Web Fonts</h3>
      <p>
        To ensure that fonts used on this website are uniform, this website uses
        so-called Web Fonts provided by Google. When you access a page on our
        website, your browser will load the required web fonts into your browser
        cache to correctly display text and fonts.
      </p>
      <p>
        To do this, the browser you use will have to establish a connection with
        Google’s servers. As a result, Google will learn that your IP address
        was used to access this website. The use of Google Web Fonts is based on
        our interest in presenting our online content in a uniform and appealing
        way. According to Art. 6 Sect. 1 lit. f GDPR, this is a legitimate
        interest.
      </p>
      <p>
        If your browser should not support Web Fonts, a standard font installed
        on your computer will be used.
      </p>
      <p>
        For more information on Google Web Fonts, please follow this link:
        <a
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          rel="noopener noreferrer"
          >https://developers.google.com/fonts/faq</a
        >
        and consult Google’s Data Privacy Declaration under:
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=en</a
        >.
      </p>
      <h3>Google Maps</h3>
      <p>
        Via an API, this website uses the mapping service Google Maps. The
        provider is Google Ireland Limited („Google“), Gordon House, Barrow
        Street, Dublin 4, Ireland.
      </p>
      <p>
        To enable the use of the Google Maps features, your IP address must be
        stored. As a rule, this information is transferred to one of Google’s
        servers in the United States, where it is archived. The operator of this
        website has no control over the data transfer.
      </p>
      <p>
        We use Google Maps to present our online content in an appealing manner
        and to make the locations disclosed on our website easy to find. This
        constitutes a legitimate interest as defined in Art. 6 Sect. 1 lit. f
        GDPR.
      </p>
      <p>
        For more information on the handling of user data, please review
        Google’s Data Privacy Declaration under:
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=en</a
        >.
      </p>
      <h3>Google reCAPTCHA</h3>
      <p>
        We use „Google reCAPTCHA“ (hereinafter referred to as „reCAPTCHA“) on
        this website. The provider is Google Ireland Limited („Google“), Gordon
        House, Barrow Street, Dublin 4, Ireland.
      </p>
      <p>
        The purpose of reCAPTCHA is to determine whether data entered on this
        website (e.g. information entered into a contact form) is being provided
        by a human user or by an automated program. To determine this, reCAPTCHA
        analyses the behaviour of the website visitors based on a variety of
        parameters. This analysis is triggered automatically as soon as the
        website visitor enters the site. For this analysis, reCAPTCHA evaluates
        a variety of data (e.g. IP address, time the website visitor spent on
        the site or cursor movements initiated by the user). The data tracked
        during such analyses are forwarded to Google.
      </p>
      <p>
        reCAPTCHA analyses run entirely in the background-color. Website
        visitors are not alerted that an analysis is underway.
      </p>
      <p>
        The data is processed on the basis of Art. 6 Sect. 1 lit. f GDPR. It is
        in the website operators legitimate interest, to protect the operator’s
        web content against misuse by automated industrial espionage systems and
        against SPAM.
      </p>
      <p>
        For more information about Google reCAPTCHA please refer to the Google
        Data Privacy Declaration and Terms Of Use under the following links:
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=en</a
        >
        and
        <a
          href="https://policies.google.com/terms?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/terms?hl=en</a
        >.
      </p>
      <h3>SoundCloud</h3>
      <p>
        We may have integrated plug-ins of the social network SoundCloud
        (SoundCloud Limited, Berners House, 47-48 Berners Street, London W1T
        3NF, Great Britain) into this website. You will be able to recognise
        such SoundCloud plug-ins by checking for the SoundCloud logo on the
        respective pages.
      </p>
      <p>
        Whenever you visit this website, a direct connection between your
        browser and the SoundCloud server will be established immediately after
        the plug-in has been activated. As a result, SoundCloud will be notified
        that you have used your IP address to visit this website. If you click
        the „Like“ button or the „Share“ button while you are logged into your
        Sound Cloud user account, you can link the content of this website to
        your SoundCloud profile and/or share the content. Consequently,
        SoundCloud will be able to allocate the visit to this website to your
        user account. We emphasize that we as the provider of the websites do
        not have any knowledge of the data transferred and the use of this data
        by SoundCloud.
      </p>
      <p>
        We use SoundCloud on the basis of Art. 6 Sect. 1 lit. f GDPR. The
        website operator has a legitimate interest in attaining the highest
        level of visibility on social media.
      </p>
      <p>
        For more information about this, please consult SoundCloud’s Data
        Privacy Declaration at:
        <a
          href="https://soundcloud.com/pages/privacy"
          target="_blank"
          rel="noopener noreferrer"
          >https://soundcloud.com/pages/privacy</a
        >.
      </p>
      <p>
        If you prefer not to have your visit to this website allocated to your
        SoundCloud user account by SoundCloud, please log out of your SoundCloud
        user account before you activate content of the SoundCloud plug-in.
      </p>
      <h3>Spotify</h3>
      <p>
        We have integrated features of the Spotify music platform into this
        website. The provider is Spotify AB, Birger Jarlsgatan 61, 113 56
        Stockholm, Sweden. You will be able to recognise Spotify plug-ins when
        you see the green logo on this website. An overview of Spotify’s
        plug-ins can be found at:
        <a
          href="https://developer.spotify.com"
          target="_blank"
          rel="noopener noreferrer"
          >https://developer.spotify.com</a
        >.
      </p>
      <p>
        The plug-in makes it possible to establish a direct connection between
        your browser and Spotify’s server when you visit this website. As a
        result, Spotify receives the information that you visited this website
        with your IP address. If you click the Spotify button while you are
        logged into your Spotify account, you have the option to link content
        from this website with your Spotify profile. Consequently, Spotify will
        be in a position to allocate your visit to this website to your user
        account.
      </p>
      <p>
        The data processing activities are based on Art. 6 Sect. 1 lit. f GDPR.
        Accordingly, the website operator has a legitimate interest in placing
        respective acoustic content on the operator’s website.
      </p>
      <p>
        For more information, please consult Spotify’s Data Protection
        Declaration under:
        <a
          href="https://www.spotify.com/us/legal/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.spotify.com/us/legal/privacy-policy/</a
        >.
      </p>
      <p>
        If you do not want Spotify to be able to allocate the visit of this
        website to your Spotify user account, please log out of your Spotify
        user account while visiting our sites.
      </p>
      <h2>8. Online marketing and partner programmes</h2>
      <h3>Amazon partner programme</h3>
      <p>
        The operators of this website participate in the Amazon EU partners’
        programme. Amazon integrates ads and links to the German Amazon website
        -  Amazon.de - into this website, which allows us to generate earnings
        in the form of advertising cost reimbursements. Amazon uses cookies for
        this programme to be able to determine the source of orders placed. This
        allows Amazon to determine that you have clicked the partner link on
        this website.
      </p>
      <p>
        The archiving of „Amazon cookies“ is based on Art. 6 lit. f GDPR. Based
        on the aforementioned regulation, the website operator has a legitimate
        interest to do this since the determination of the website operator’s
        affiliate earnings is contingent upon the placement of cookies.
      </p>
      <p>
        For more information on Amazon’s data usage, please consult Amazon’s
        Data Privacy Declaration under the following link:
        <a
          href="https://www.amazon.com/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=468496"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.amazon.com/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=468496</a
        >.
      </p>
      <h2>9. Payment service providers and resellers</h2>
      <h3>PayPal</h3>
      <p>
        Among other options, we offer payment via PayPal on this website. The
        provider of this payment processing service is PayPal (Europe) S.à.r.l.
        et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (hereinafter
        referred to as „PayPal“).
      </p>
      <p>
        If you choose payment via PayPal, we will share the payment information
        you enter with PayPal.
      </p>
      <p>
        The legal basis for the sharing of your data with PayPal is Art. 6 Sect.
        1 lit. a GDPR (consent) as well as Art. 6 Sect. 1 lit. b GDPR
        (processing for the fulfilment of a contract). You have the option to at
        any time revoke your consent to the processing of your data. Such a
        revocation shall not have any impact on the effectiveness of data
        processing transactions that occurred in the past.
      </p>
      <h3>Klarna</h3>
      <p>
        Among other options, we offer payment through the services of Klarna on
        this website. The provider is Klarna AB, Sveavägen 46, 111 34 Stockholm,
        Sweden (hereinafter referred to as „Klarna“).
      </p>
      <p>
        Klarna offers a wide spectrum of payment options (e.g. payment by
        instalment). If you should decide to pay through Klarna (Klarna checkout
        solution), Klarna will collect personal data from you. For specifics,
        please review Klarna’s Data Protection Declaration by following this
        link:
        <a
          href="https://www.klarna.com/us/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.klarna.com/us/privacy-policy/</a
        >.
      </p>
      <p>
        Klarna uses cookies to optimise the use of Klarna checkout solutions.
        The optimisation of the checkout solution constitutes a legitimate
        interest as defined in Art. 6 Sect. 1 lit. f GDPR. Cookies are small
        text files that can be stored on your device and that do not cause any
        damage to your device. The information stays on your device until you
        delete it. For details concerning the use of Klarna cookies, please
        follow this link:
        <a
          href="https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf"
          target="_blank"
          rel="noopener noreferrer"
          >https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf</a
        >.
      </p>
      <p>
        The legal basis for the sharing of your data with PayPal is Art. 6 Sect.
        1 lit. a GDPR (consent) as well as Art. 6 Sect. 1 lit. b GDPR
        (processing for the fulfilment of a contract). You have the option to at
        any time revoke your consent to the processing of your data. Such a
        revocation shall not have any impact on the effectiveness of data
        processing transactions that occurred in the past.
      </p>
      <h3>instant transfer Sofort</h3>
      <p>
        Among other options, we offer the payment service called „instant
        transfer Sofort“ on this website. The provider of this payment option is
        the Sofort GmbH, Theresienhöhe 12, 80339 München, Germany (hereinafter
        referred to as „Sofort GmbH“).
      </p>
      <p>
        With the assistance of the „instant transfer Sofort“ tool, we receive a
        payment confirmation from the Sofort GmbH in real time, which allows us
        to instantly start to fulfil our obligations to you.
      </p>
      <p>
        When you choose the „instant transfer Sofort“ payment option, you must
        send a PIN and a valid TAN to the Sofort GmbH, which allows the company
        to log into your online banking account. Upon logging in, the Sofort
        GmbH will verify your account balance and will execute the bank transfer
        to us with the assistance of the TAN you provided. Subsequently, the
        company sends us an immediate transaction confirmation. After Sofort
        GmbH has logged in, the system will also automatically verify your
        revenues and check the credit limit of your pre-approved overdraft
        credit line and the existence of other accounts along with their
        balances.
      </p>
      <p>
        Along with the PIN and TAN numbers, the system also transfers the
        payment information you entered along with personal data to the Sofort
        GmbH. Your personal data comprise your first and last name, address,
        phone number(s), e-mail address, IP address as well as any other data
        required for the processing of the payment transaction. This data must
        be transferred in order to be able to determine your identity with
        absolute certainty and to prevent attempts to commit fraud.
      </p>
      <p>
        The legal basis for the sharing of your information with the Sofort GmbH
        is Art. 6 Sect. 1 lit. a GDPR (consent) as well as Art. 6 Sect. 1 lit. b
        GDPR (processing for fulfilment of a contract). You have the option to
        at any time revoke your consent to the processing of your data. Such a
        revocation shall not have any impact on the effectiveness of data
        processing transactions that occurred in the past.
      </p>
      <p>
        For details on payments made with the instant transfer option, please
        follow these links:
        <a
          href="https://www.sofort.de/datenschutz.html"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.sofort.de/datenschutz.html</a
        >
        and
        <a
          href="https://www.klarna.com/sofort/"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.klarna.com/sofort/</a
        >.
      </p>
      <h3>Paydirekt</h3>
      <p>
        Among other options, we offer payment via Paydirekt on this website. The
        provider of this payment service is the Paydirekt GmbH, Hamburger Allee
        26-28, 60486 Frankfurt am Main, Germany (hereinafter referred to as
        „Paydirekt“).
      </p>
      <p>
        Whenever you transact a payment via Paydirekt, Paydirekt collects
        transaction data and passes the data on to the bank you are registered
        with under Paydirekt. Besides the information required for the payment
        transaction itself, Paydirekt collects other data in conjunction with
        the processing of your transaction, such as the shipping address or the
        individual items in the shopping cart.
      </p>
      <p>
        Subsequently, Paydirekt authenticates the transaction with the
        assistance of an authentication process that has been archived with the
        bank for this purpose. Next, your payment amount will be transferred to
        our account from your account. We do not have any access to your account
        information; neither do third parties.
      </p>
      <p>
        For details concerning payment via Paydirekt, please review the General
        Terms and Conditions as well as the Data Protection Provisions of
        Paydirekt by following this link:
        <a
          href="https://www.paydirekt.de/agb/index.html"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.paydirekt.de/agb/index.html</a
        >.
      </p>
      <h2>10. Custom Services</h2>
      <h3>Job Applications</h3>
      <p>
        We offer website visitors the opportunity to submit job applications to
        us (e.g. via e-mail, via postal services on by submitting the online job
        application form). Below, we will brief you on the scope, purpose and
        use of the personal data collected from you in conjunction with the
        application process. We assure you that the collection, processing and
        use of your data will occur in compliance with the applicable data
        privacy rights and all other statutory provisions and that your data
        will always be treated as strictly confidential.
      </p>
      <p><strong>Scope and purpose of the collection of data</strong></p>
      <p>
        If you submit a job application to us, we will process any affiliated
        personal data (e.g. contact and communications data, application
        documents, notes taken during job interviews, etc.), if they are
        required to make a decision concerning the establishment or an
        employment relationship. The legal grounds for the aforementioned are §
        26 New GDPR according to German Law (Negotiation of an Employment
        Relationship), Art. 6 Sect. 1 lit. b GDPR (General Contract
        Negotiations) and – provided you have given us your consent – Art. 6
        Sect. 1 lit. a GDPR. You may revoke any consent given at any time.
        Within our company, your personal data will only be shared with
        individuals who are involved in the processing of your job application.
      </p>
      <p>
        If your job application should result in your recruitment, the data you
        have submitted will be archived on the grounds of § 26 New GDPR and Art.
        6 Sect. 1 lit. b GDPR for the purpose of implementing the employment
        relationship in our data processing system.
      </p>
      <p><strong>Data Archiving Period</strong></p>
      <p>
        If we are unable to make you a job offer or you reject a job offer or
        withdraw your application, we reserve the right to retain the data you
        have submitted on the basis of our legitimate interests (Art. 6 para. 1
        lit. f DSGVO) for up to 6 months from the end of the application
        procedure (rejection or withdrawal of the application). Afterwards the
        data will be deleted, and the physical application documents will be
        destroyed. The storage serves in particular as evidence in the event of
        a legal dispute. If it is evident that the data will be required after
        the expiry of the 6-month period (e.g. due to an impending or pending
        legal dispute), deletion will only take place when the purpose for
        further storage no longer applies.
      </p>
      <p>
        Longer storage may also take place if you have given your agreement
        (Article 6 (1) (a) GDPR) or if statutory data retention requirements
        preclude the deletion.
      </p>
    </div>
    <div *ngIf="language === 'de'" fxLayout="column" fxLayoutGap="16px">
      <h1>Datenschutzerklärung</h1>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3>
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können. Ausführliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>
      <h3>Datenerfassung auf dieser Website</h3>
      <p>
        <strong
          >Wer ist verantwortlich für die Datenerfassung auf dieser
          Website?</strong
        >
      </p>
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
        Website entnehmen.
      </p>
      <p><strong>Wie erfassen wir Ihre Daten?</strong></p>
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben.
      </p>
      <p>
        Andere Daten werden automatisch beim Besuch der Website durch unsere
        IT-Systeme erfasst. Das sind vor allem technische Daten (z. B.
        Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
        Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
        betreten.
      </p>
      <p><strong>Wofür nutzen wir Ihre Daten?</strong></p>
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </p>
      <p><strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong></p>
      <p>
        Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
        dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
        Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen
        Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei
        der zuständigen Aufsichtsbehörde zu.
      </p>
      <p>
        Außerdem haben Sie das Recht, unter bestimmten Umständen die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung unter
        „Recht auf Einschränkung der Verarbeitung“.
      </p>
      <h3>Analyse-Tools und Tools von Drittanbietern</h3>
      <p>
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
        ausgewertet werden. Das geschieht vor allem mit Cookies und mit
        sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt
        in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
        zurückverfolgt werden.
      </p>
      <p>
        Sie können dieser Analyse widersprechen oder sie durch die
        Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen
        zu diesen Tools und über Ihre Widerspruchsmöglichkeiten finden Sie in
        der folgenden Datenschutzerklärung.
      </p>
      <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
      <h3>Datenschutz</h3>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </p>
      <p>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
        sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <h3>Hinweis zur verantwortlichen Stelle</h3>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <p>
        Antonio Maioriello<br />
        Columbusstraße 11<br />
        71686 Remseck
      </p>

      <p>
        Phone: +4915771246217<br />
        E-mail: antonio.maioriello@ma-technology.de
      </p>
      <p>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
        o. Ä.) entscheidet.
      </p>
      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
        uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
        bleibt vom Widerruf unberührt.
      </p>
      <h3>
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
        gegen Direktwerbung (Art. 21 DSGVO)
      </h3>
      <p>
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
        DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
        IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
        AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
        DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
        IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI
        DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER
        DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
        VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
      </p>
      <p>
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
        BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
        VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
        DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT
        ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
        WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
        MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
        ABS. 2 DSGVO).
      </p>
      <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
      <p>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
      </p>
      <h3>Recht auf Datenübertragbarkeit</h3>
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </p>
      <h3>SSL- bzw. TLS-Verschlüsselung</h3>
      <p>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p>
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h3>Verschlüsselter Zahlungsverkehr auf dieser Website</h3>
      <p>
        Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine
        Verpflichtung, uns Ihre Zahlungsdaten (z. B. Kontonummer bei
        Einzugsermächtigung) zu übermitteln, werden diese Daten zur
        Zahlungsabwicklung benötigt.
      </p>
      <p>
        Der Zahlungsverkehr über die gängigen Zahlungsmittel (Visa/MasterCard,
        Lastschriftverfahren) erfolgt ausschließlich über eine verschlüsselte
        SSL- bzw. TLS-Verbindung. Eine verschlüsselte Verbindung erkennen Sie
        daran, dass die Adresszeile des Browsers von „http://“ auf „https://“
        wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p>
        Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie an
        uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h3>Auskunft, Löschung und Berichtigung</h3>
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
        dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
        Daten können Sie sich jederzeit unter der im Impressum angegebenen
        Adresse an uns wenden.
      </p>
      <h3>Recht auf Einschränkung der Verarbeitung</h3>
      <p>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
        unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf
        Einschränkung der Verarbeitung besteht in folgenden Fällen:
      </p>
      <ul>
        <li>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
          überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </li>
        <li>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah/geschieht, können Sie statt der Löschung die Einschränkung der
          Datenverarbeitung verlangen.
        </li>
        <li>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung, Verteidigung oder Geltendmachung von
          Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
          die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </li>
        <li>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </li>
      </ul>
      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
        haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
        Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
        natürlichen oder juristischen Person oder aus Gründen eines wichtigen
        öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
        verarbeitet werden.
      </p>
      <h3>Widerspruch gegen Werbe-E-Mails</h3>
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
        Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor.
      </p>
      <h2>3. Datenerfassung auf dieser Website</h2>
      <h3>Cookies</h3>
      <p>
        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
        richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
        Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
        sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
        Rechner abgelegt werden und die Ihr Browser speichert.
      </p>
      <p>
        Die meisten der von uns verwendeten Cookies sind so genannte
        „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
        gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie
        diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim
        nächsten Besuch wiederzuerkennen.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browsers
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <p>
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs
        oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
        (z. B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von
        Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein
        berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine
        entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
        Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf
        Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
        widerrufbar.
      </p>
      <p>
        Soweit andere Cookies (z. B. Cookies zur Analyse Ihres Surfverhaltens)
        gespeichert werden, werden diese in dieser Datenschutzerklärung
        gesondert behandelt.
      </p>
      <h3>Server-Log-Dateien</h3>
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
      </p>
      <ul>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>IP-Adresse</li>
      </ul>
      <p>
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen.
      </p>
      <p>
        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
        f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
        technisch fehlerfreien Darstellung und der Optimierung seiner Website –
        hierzu müssen die Server-Log-Files erfasst werden.
      </p>
      <h3>Kontaktformular</h3>
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
      </p>
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
      </p>
      <p>
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
        bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
        Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
      <p>
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
        Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
        (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
        gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
        Einwilligung weiter.
      </p>
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder auf unseren
        berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein
        berechtigtes Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen haben.
      </p>
      <p>
        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
        bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
        Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
        (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
        gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen
        – bleiben unberührt.
      </p>
      <h3>Registrierung auf dieser Website</h3>
      <p>
        Sie können sich auf dieser Website registrieren, um zusätzliche
        Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
        verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder
        Dienstes, für den Sie sich registriert haben. Die bei der Registrierung
        abgefragten Pflichtangaben müssen vollständig angegeben werden.
        Anderenfalls werden wir die Registrierung ablehnen.
      </p>
      <p>
        Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch
        notwendigen Änderungen nutzen wir die bei der Registrierung angegebene
        E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
      </p>
      <p>
        Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
        zum Zwecke der Durchführung des durch die Registrierung begründeten
        Nutzungsverhältnisses und ggf. zur Anbahnung weiterer Verträge (Art. 6
        Abs. 1 lit. b DSGVO).
      </p>
      <p>
        Die bei der Registrierung erfassten Daten werden von uns gespeichert,
        solange Sie auf dieser Website registriert sind und werden anschließend
        gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
      </p>
      <h3>Kommentarfunktion auf dieser Website</h3>
      <p>
        Für die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar
        auch Angaben zum Zeitpunkt der Erstellung des Kommentars, Ihre
        E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen gewählte
        Nutzername gespeichert.
      </p>
      <p><strong>Speicherung der IP-Adresse</strong></p>
      <p>
        Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die
        Kommentare verfassen. Da wir Kommentare auf dieser Website nicht vor der
        Freischaltung prüfen, benötigen wir diese Daten, um im Falle von
        Rechtsverletzungen wie Beleidigungen oder Propaganda gegen den Verfasser
        vorgehen zu können.
      </p>
      <p><strong>Abonnieren von Kommentaren</strong></p>
      <p>
        Als Nutzer der Seite können Sie nach einer Anmeldung Kommentare
        abonnieren. Sie erhalten eine Bestätigungsemail, um zu prüfen, ob Sie
        der Inhaber der angegebenen E-Mail-Adresse sind. Sie können diese
        Funktion jederzeit über einen Link in den Info-Mails abbestellen. Die im
        Rahmen des Abonnierens von Kommentaren eingegebenen Daten werden in
        diesem Fall gelöscht; wenn Sie diese Daten für andere Zwecke und an
        anderer Stelle (z. B. Newsletterbestellung) an uns übermittelt haben,
        verbleiben die jedoch bei uns.
      </p>
      <p><strong>Speicherdauer der Kommentare</strong></p>
      <p>
        Die Kommentare und die damit verbundenen Daten (z. B. IP-Adresse) werden
        gespeichert und verbleiben auf dieser Website, bis der kommentierte
        Inhalt vollständig gelöscht wurde oder die Kommentare aus rechtlichen
        Gründen gelöscht werden müssen (z. B. beleidigende Kommentare).
      </p>
      <p><strong>Rechtsgrundlage</strong></p>
      <p>
        Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer Einwilligung
        (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von Ihnen erteilte
        Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung
        per E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten
        Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
      </p>
      <h3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>
      <p>
        Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit
        sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des
        Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf
        Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
        zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
        Personenbezogene Daten über die Inanspruchnahme dieser Website
        (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies
        erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu
        ermöglichen oder abzurechnen.
      </p>
      <p>
        Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
        Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
        Aufbewahrungsfristen bleiben unberührt.
      </p>
      <h3>
        Datenübermittlung bei Vertragsschluss für Online-Shops, Händler und
        Warenversand
      </h3>
      <p>
        Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im
        Rahmen der Vertragsabwicklung notwendig ist, etwa an die mit der
        Lieferung der Ware betrauten Unternehmen oder das mit der
        Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende
        Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der
        Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten
        an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der Werbung,
        erfolgt nicht.
      </p>
      <p>
        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der
        die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
        vorvertraglicher Maßnahmen gestattet.
      </p>
      <h3>
        Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale
        Inhalte
      </h3>
      <p>
        Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im
        Rahmen der Vertragsabwicklung notwendig ist, etwa an das mit der
        Zahlungsabwicklung beauftragte Kreditinstitut.
      </p>
      <p>
        Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann,
        wenn Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe
        Ihrer Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken
        der Werbung, erfolgt nicht.
      </p>
      <p>
        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der
        die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
        vorvertraglicher Maßnahmen gestattet.
      </p>
      <h2>4. Soziale Medien</h2>
      <h3>Twitter Plugin</h3>
      <p>
        Auf dieser Website sind Funktionen des Dienstes Twitter eingebunden.
        Diese Funktionen werden angeboten durch die Twitter Inc., 1355 Market
        Street, Suite 900, San Francisco, CA 94103, USA. Durch das Benutzen von
        Twitter und der Funktion „Re-Tweet“ werden die von Ihnen besuchten
        Websites mit Ihrem Twitter-Account verknüpft und anderen Nutzern bekannt
        gegeben. Dabei werden auch Daten an Twitter übertragen. Wir weisen
        darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
        der übermittelten Daten sowie deren Nutzung durch Twitter erhalten.
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
        Twitter unter:
        <a
          href="https://twitter.com/de/privacy"
          target="_blank"
          rel="noopener noreferrer"
          >https://twitter.com/de/privacy</a
        >.
      </p>
      <p>
        Die Verwendung des Twitter-Plugins erfolgt auf Grundlage von Art. 6 Abs.
        1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
        einer möglichst umfangreichen Sichtbarkeit in den Sozialen Medien.
      </p>
      <p>
        Ihre Datenschutzeinstellungen bei Twitter können Sie in den
        Konto-Einstellungen unter
        <a
          href="https://twitter.com/account/settings"
          target="_blank"
          rel="noopener noreferrer"
          >https://twitter.com/account/settings</a
        >
        ändern.
      </p>
      <h3>Instagram Plugin</h3>
      <p>
        Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden.
        Diese Funktionen werden angeboten durch die Instagram Inc., 1601 Willow
        Road, Menlo Park, CA 94025, USA integriert.
      </p>
      <p>
        Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch
        Anklicken des Instagram-Buttons die Inhalte dieser Website mit Ihrem
        Instagram-Profil verlinken. Dadurch kann Instagram den Besuch dieser
        Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir
        als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten
        Daten sowie deren Nutzung durch Instagram erhalten.
      </p>
      <p>
        Die Verwendung des Instagram-Plugins erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
        an einer möglichst umfangreichen Sichtbarkeit in den Sozialen Medien.
      </p>
      <p>
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
        Instagram:
        <a
          href="https://instagram.com/about/legal/privacy/"
          target="_blank"
          rel="noopener noreferrer"
          >https://instagram.com/about/legal/privacy/</a
        >.
      </p>
      <h2>5. Analyse-Tools und Werbung</h2>
      <h3>Google Analytics</h3>
      <p>
        Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
        Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow
        Street, Dublin 4, Irland.
      </p>
      <p>
        Google Analytics verwendet so genannte „Cookies“. Das sind Textdateien,
        die auf Ihrem Computer gespeichert werden und die eine Analyse der
        Benutzung der Website durch Sie ermöglichen. Die durch den Cookie
        erzeugten Informationen über Ihre Benutzung dieser Website werden in der
        Regel an einen Server von Google in den USA übertragen und dort
        gespeichert.
      </p>
      <p>
        Die Speicherung von Google-Analytics-Cookies und die Nutzung dieses
        Analyse-Tools erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse an der Analyse des
        Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu
        optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde (z.
        B. eine Einwilligung zur Speicherung von Cookies), erfolgt die
        Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
        DSGVO; die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p><strong>IP Anonymisierung</strong></p>
      <p>
        Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert.
        Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten
        der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
        über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA
        gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server
        von Google in den USA übertragen und dort gekürzt. Im Auftrag des
        Betreibers dieser Website wird Google diese Informationen benutzen, um
        Ihre Nutzung der Website auszuwerten, um Reports über die
        Websiteaktivitäten zusammenzustellen und um weitere mit der
        Websitenutzung und der Internetnutzung verbundene Dienstleistungen
        gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
        Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengeführt.
      </p>
      <p><strong>Browser Plugin</strong></p>
      <p>
        Sie können die Speicherung der Cookies durch eine entsprechende
        Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
        darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
        Funktionen dieser Website vollumfänglich werden nutzen können. Sie
        können darüber hinaus die Erfassung der durch den Cookie erzeugten und
        auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
        Google sowie die Verarbeitung dieser Daten durch Google verhindern,
        indem Sie das unter dem folgenden Link verfügbare Browser-Plugin
        herunterladen und installieren:
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://tools.google.com/dlpage/gaoptout?hl=de</a
        >.
      </p>
      <p><strong>Widerspruch gegen Datenerfassung</strong></p>
      <p>
        Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern,
        indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie
        gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser
        Website verhindert:
        <a href="javascript:gaOptout();">Google Analytics deaktivieren</a>.
      </p>
      <p>
        Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
        finden Sie in der Datenschutzerklärung von Google:
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://support.google.com/analytics/answer/6004245?hl=de</a
        >.
      </p>
      <p><strong>Auftragsverarbeitung</strong></p>
      <p>
        Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
        abgeschlossen und setzen die strengen Vorgaben der deutschen
        Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
      </p>
      <p><strong>Demografische Merkmale bei Google Analytics</strong></p>
      <p>
        Diese Website nutzt die Funktion „demografische Merkmale“ von Google
        Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu
        Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese
        Daten stammen aus interessenbezogener Werbung von Google sowie aus
        Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten
        Person zugeordnet werden. Sie können diese Funktion jederzeit über die
        Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die
        Erfassung Ihrer Daten durch Google Analytics wie im Punkt „Widerspruch
        gegen Datenerfassung“ dargestellt generell untersagen.
      </p>
      <p><strong>Speicherdauer</strong></p>
      <p>
        Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit
        Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z. B.
        DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach 14
        Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie unter
        folgendem Link:
        <a
          href="https://support.google.com/analytics/answer/7667196?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://support.google.com/analytics/answer/7667196?hl=de</a
        >
      </p>

      <h3>Google AdSense</h3>
      <p>
        Diese Website benutzt Google AdSense, einen Dienst zum Einbinden von
        Werbeanzeigen. Anbieter ist die Google Ireland Limited („Google“),
        Gordon House, Barrow Street, Dublin 4, Irland.
      </p>
      <p>
        Google AdSense verwendet sogenannte „Cookies“, Textdateien, die auf
        Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der
        Website ermöglichen. Google AdSense verwendet auch so genannte Web
        Beacons (unsichtbare Grafiken). Durch diese Web Beacons können
        Informationen wie der Besucherverkehr auf diesen Seiten ausgewertet
        werden.
      </p>
      <p>
        Die durch Cookies und Web Beacons erzeugten Informationen über die
        Benutzung dieser Website (einschließlich Ihrer IP-Adresse) und
        Auslieferung von Werbeformaten werden an einen Server von Google in den
        USA übertragen und dort gespeichert. Diese Informationen können von
        Google an Vertragspartner von Google weiter gegeben werden. Google wird
        Ihre IP-Adresse jedoch nicht mit anderen von Ihnen gespeicherten Daten
        zusammenführen.
      </p>
      <p>
        Die Speicherung von AdSense-Cookies erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
        an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch
        seine Werbung zu optimieren.
      </p>
      <p>
        Sie können die Installation der Cookies durch eine entsprechende
        Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch
        darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
        Funktionen dieser Website voll umfänglich nutzen können. Durch die
        Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über
        Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und
        Weise und zu dem zuvor benannten Zweck einverstanden.
      </p>
      <h3>Google Analytics Remarketing</h3>
      <p>
        Diese Website nutzt die Funktionen von Google Analytics Remarketing in
        Verbindung mit den geräteübergreifenden Funktionen von Google AdWords
        und Google DoubleClick. Anbieter ist die Google Ireland Limited
        („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
      </p>
      <p>
        Diese Funktion ermöglicht es die mit Google Analytics Remarketing
        erstellten Werbe-Zielgruppen mit den geräteübergreifenden Funktionen von
        Google AdWords und Google DoubleClick zu verknüpfen. Auf diese Weise
        können interessenbezogene, personalisierte Werbebotschaften, die in
        Abhängigkeit Ihres früheren Nutzungs- und Surfverhaltens auf einem
        Endgerät (z. B. Handy) an Sie angepasst wurden auch auf einem anderen
        Ihrer Endgeräte (z. B. Tablet oder PC) angezeigt werden.
      </p>
      <p>
        Haben Sie eine entsprechende Einwilligung erteilt, verknüpft Google zu
        diesem Zweck Ihren Web- und App-Browserverlauf mit Ihrem Google-Konto.
        Auf diese Weise können auf jedem Endgerät auf dem Sie sich mit Ihrem
        Google-Konto anmelden, dieselben personalisierten Werbebotschaften
        geschaltet werden.
      </p>
      <p>
        Zur Unterstützung dieser Funktion erfasst Google Analytics
        google-authentifizierte IDs der Nutzer, die vorübergehend mit unseren
        Google-Analytics-Daten verknüpft werden, um Zielgruppen für die
        geräteübergreifende Anzeigenwerbung zu definieren und zu erstellen.
      </p>
      <p>
        Sie können dem geräteübergreifenden Remarketing/Targeting dauerhaft
        widersprechen, indem Sie personalisierte Werbung deaktivieren; folgen
        Sie hierzu diesem Link:
        <a
          href="https://www.google.com/settings/ads/onweb/"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.google.com/settings/ads/onweb/</a
        >.
      </p>
      <p>
        Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto erfolgt
        ausschließlich auf Grundlage Ihrer Einwilligung, die Sie bei Google
        abgeben oder widerrufen können (Art. 6 Abs. 1 lit. a DSGVO). Bei
        Datenerfassungsvorgängen, die nicht in Ihrem Google-Konto
        zusammengeführt werden (z. B. weil Sie kein Google-Konto haben oder der
        Zusammenführung widersprochen haben) beruht die Erfassung der Daten auf
        Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse ergibt sich
        daraus, dass der Websitebetreiber ein Interesse an der anonymisierten
        Analyse der Websitebesucher zu Werbezwecken hat.
      </p>
      <p>
        Weitergehende Informationen und die Datenschutzbestimmungen finden Sie
        in der Datenschutzerklärung von Google unter:
        <a
          href="https://policies.google.com/technologies/ads?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/technologies/ads?hl=de</a
        >.
      </p>
      <h3>Google AdWords und Google Conversion-Tracking</h3>
      <p>
        Diese Website verwendet Google AdWords. AdWords ist ein
        Online-Werbeprogramm der Google Ireland Limited („Google“), Gordon
        House, Barrow Street, Dublin 4, Irland.
      </p>
      <p>
        Im Rahmen von Google AdWords nutzen wir das so genannte
        Conversion-Tracking. Wenn Sie auf eine von Google geschaltete Anzeige
        klicken wird ein Cookie für das Conversion-Tracking gesetzt. Bei Cookies
        handelt es sich um kleine Textdateien, die der Internet-Browser auf dem
        Computer des Nutzers ablegt. Diese Cookies verlieren nach 30 Tagen ihre
        Gültigkeit und dienen nicht der persönlichen Identifizierung der Nutzer.
        Besucht der Nutzer bestimmte Seiten dieser Website und das Cookie ist
        noch nicht abgelaufen, können Google und wir erkennen, dass der Nutzer
        auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
      </p>
      <p>
        Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies können
        nicht über die Websites von AdWords-Kunden nachverfolgt werden. Die
        mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu,
        Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für
        Conversion-Tracking entschieden haben. Die Kunden erfahren die
        Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu
        einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet
        wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer
        persönlich identifizieren lassen. Wenn Sie nicht am Tracking teilnehmen
        möchten, können Sie dieser Nutzung widersprechen, indem Sie das Cookie
        des Google Conversion-Trackings über ihren Internet-Browser unter
        Nutzereinstellungen leicht deaktivieren. Sie werden sodann nicht in die
        Conversion-Tracking Statistiken aufgenommen.
      </p>
      <p>
        Die Speicherung von „Conversion-Cookies“ und die Nutzung dieses
        Tracking-Tools erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
        Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des
        Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu
        optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde (z.
        B. eine Einwilligung zur Speicherung von Cookies), erfolgt die
        Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
        DSGVO; die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Mehr Informationen zu Google AdWords und Google Conversion-Tracking
        finden Sie in den Datenschutzbestimmungen von Google:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=de</a
        >.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browsers
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <h3>Google DoubleClick</h3>
      <p>
        Diese Website nutzt Funktionen von Google DoubleClick. Anbieter ist die
        Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA,
        (nachfolgend „DoubleClick“).
      </p>
      <p>
        DoubleClick wird eingesetzt, um Ihnen interessenbezogene Werbeanzeigen
        im gesamten Google-Werbenetzwerk anzuzeigen. Die Werbeanzeigen können
        mit Hilfe von DoubleClick zielgerichtet an die Interessen des jeweiligen
        Betrachters angepasst werden.  So kann unsere Werbung beispielsweise in
        Google-Suchergebnissen oder in Werbebannern, die mit DoubleClick
        verbunden sind, angezeigt werden.
      </p>
      <p>
        Um den Nutzern interessengerechte Werbung anzeigen zu können, muss
        DoubleClick den jeweiligen Betrachter wiedererkennen können. Hierzu wird
        im Browser des Nutzers ein Cookie gespeichert, hinter welchem die vom
        Nutzer besuchten Webseiten, Klicks und verschiedene weitere
        Informationen hinterlegt sind. Diese Informationen werden zu einem
        pseudonymen Nutzerprofil zusammengefasst, um dem betreffenden Nutzer
        interessengerechte Werbung anzuzeigen.
      </p>
      <p>
        Die Nutzung von Google DoubleClick erfolgt im Interesse zielgerichteter
        Werbemaßnahmen. Dies stellt ein berechtigtes Interesse im Sinne des Art.
        6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
        abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies),
        erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1
        lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass er keine Cookies mehr
        speichert. Hiermit kann jedoch eine Beschränkung der zugänglichen
        Webseitenfunktionen verbunden sein. Zudem wird darauf hingewiesen, dass
        DoubleClick unter Umständen auch andere Technologien zur Bildung von
        Nutzerprofilen verwenden kann. Das Abschalten von Cookies bietet daher
        keine Garantie dafür, dass keine Nutzerprofile mehr erstellt werden.
      </p>
      <p>
        Weitere Informationen zu Widerspruchsmöglichkeiten gegen die von Google
        eingeblendeten Werbeanzeigen entnehmen Sie folgenden Links:
        <a
          href="https://policies.google.com/technologies/ads"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/technologies/ads</a
        >
        und
        <a
          href="https://adssettings.google.com/authenticated"
          target="_blank"
          rel="noopener noreferrer"
          >https://adssettings.google.com/authenticated</a
        >.
      </p>
      <h2>6. Newsletter</h2>
      <h3>Newsletterdaten</h3>
      <p>
        Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
        benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche
        uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen
        E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden
        sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis
        erhoben. Diese Daten verwenden wir ausschließlich für den Versand der
        angeforderten Informationen und geben diese nicht an Dritte weiter.
      </p>
      <p>
        Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten
        erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
        lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der
        E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können
        Sie jederzeit widerrufen, etwa über den „Austragen“-Link im Newsletter.
        Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge
        bleibt vom Widerruf unberührt.
      </p>
      <p>
        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
        Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns
        bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung
        des Newsletters aus der Newsletterverteilerliste gelöscht. Daten, die zu
        anderen Zwecken bei uns gespeichert wurden bleiben hiervon unberührt.
      </p>
      <p>
        Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
        E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer
        Blacklist gespeichert, um künftige Mailings zu verhindern. Die Daten aus
        der Blacklist werden nur für diesen Zweck verwendet und nicht mit
        anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als
        auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim
        Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs.
        1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht
        befristet.
        <strong
          >Sie können der Speicherung widersprechen, sofern Ihre Interessen
          unser berechtigtes Interesse überwiegen.</strong
        >
      </p>
      <h2>7. Plugins und Tools</h2>
      <h3>YouTube mit erweitertem Datenschutz</h3>
      <p>
        Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist
        die Google Ireland Limited („Google“), Gordon House, Barrow Street,
        Dublin 4, Irland.
      </p>
      <p>
        Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt
        laut YouTube, dass YouTube keine Informationen über die Besucher auf
        dieser Website speichert, bevor diese sich das Video ansehen. Die
        Weitergabe von Daten an YouTube-Partner wird durch den erweiterten
        Datenschutzmodus hingegen nicht zwingend ausgeschlossen. So stellt
        YouTube – unabhängig davon, ob Sie sich ein Video ansehen – eine
        Verbindung zum Google DoubleClick-Netzwerk her.
      </p>
      <p>
        Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
        Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
        YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Wenn
        Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube,
        Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies
        können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account
        ausloggen.
      </p>
      <p>
        Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies
        auf Ihrem Endgerät speichern. Mit Hilfe dieser Cookies kann YouTube
        Informationen über Besucher dieser Website erhalten. Diese Informationen
        werden u. a. verwendet, um Videostatistiken zu erfassen, die
        Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen.
        Die Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen.
      </p>
      <p>
        Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
        Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen Einfluss
        haben.
      </p>
      <p>
        Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
        Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
        Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
        entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
        Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf
        Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
        widerrufbar.
      </p>
      <p>
        Weitere Informationen über Datenschutz bei YouTube finden Sie in deren
        Datenschutzerklärung unter:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=de</a
        >.
      </p>
      <h3>Vimeo</h3>
      <p>
        Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die
        Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.
      </p>
      <p>
        Wenn Sie eine unserer mit einem Vimeo-Plugin ausgestatteten Seiten
        besuchen, wird eine Verbindung zu den Servern von Vimeo hergestellt.
        Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie
        besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Dies gilt auch dann,
        wenn Sie nicht bei Vimeo eingeloggt sind oder keinen Account bei Vimeo
        besitzen. Die von Vimeo erfassten Informationen werden an den
        Vimeo-Server in den USA übermittelt.
      </p>
      <p>
        Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, ermöglichen Sie Vimeo,
        Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies
        können Sie verhindern, indem Sie sich aus Ihrem Vimeo-Account ausloggen.
      </p>
      <p>
        Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden
        Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
        Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
        entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
        Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf
        Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
        widerrufbar.
      </p>
      <p>
        Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von Vimeo unter:
        <a
          href="https://vimeo.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          >https://vimeo.com/privacy</a
        >.
      </p>
      <h3>Google Web Fonts</h3>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
        genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
        einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
        Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
      </p>
      <p>
        Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
        Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber,
        dass über Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung
        von Google Web Fonts erfolgt im Interesse einer einheitlichen und
        ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      </p>
      <p>
        Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift
        von Ihrem Computer genutzt.
      </p>
      <p>
        Weitere Informationen zu Google Web Fonts finden Sie unter
        <a
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          rel="noopener noreferrer"
          >https://developers.google.com/fonts/faq</a
        >
        und in der Datenschutzerklärung von Google:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=de</a
        >.
      </p>
      <h3>Google Maps</h3>
      <p>
        Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter
        ist die Google Ireland Limited („Google“), Gordon House, Barrow Street,
        Dublin 4, Irland.
      </p>
      <p>
        Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP
        Adresse zu speichern. Diese Informationen werden in der Regel an einen
        Server von Google in den USA übertragen und dort gespeichert. Der
        Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
      </p>
      <p>
        Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
        Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit
        der von uns auf der Website angegebenen Orte. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      </p>
      <p>
        Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von Google:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=de</a
        >.
      </p>
      <h3>Google reCAPTCHA</h3>
      <p>
        Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser
        Website. Anbieter ist die Google Ireland Limited („Google“), Gordon
        House, Barrow Street, Dublin 4, Irland.
      </p>
      <p>
        Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser
        Website (z. B. in einem Kontaktformular) durch einen Menschen oder durch
        ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das
        Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese
        Analyse beginnt automatisch, sobald der Websitebesucher die Website
        betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus
        (z. B. IP-Adresse, Verweildauer des Websitebesuchers auf der Website
        oder vom Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten
        Daten werden an Google weitergeleitet.
      </p>
      <p>
        Die reCAPTCHA-Analysen laufen vollständig im Hintergrund.
        Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
        stattfindet.
      </p>
      <p>
        Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
        DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse daran, seine
        Webangebote vor missbräuchlicher automatisierter Ausspähung und vor SPAM
        zu schützen.
      </p>
      <p>
        Weitere Informationen zu Google reCAPTCHA entnehmen Sie den
        Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen unter
        folgenden Links:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=de</a
        >
        und
        <a
          href="https://policies.google.com/terms?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/terms?hl=de</a
        >.
      </p>
      <h3>SoundCloud</h3>
      <p>
        Auf dieser Website können Plugins des sozialen Netzwerks SoundCloud
        (SoundCloud Limited, Berners House, 47-48 Berners Street, London W1T
        3NF, Großbritannien.) integriert sein. Die SoundCloud-Plugins erkennen
        Sie an dem SoundCloud-Logo auf den betroffenen Seiten.
      </p>
      <p>
        Wenn Sie diese Website besuchen, wird nach Aktivierung des Plugin eine
        direkte Verbindung zwischen Ihrem Browser und dem SoundCloud-Server
        hergestellt. SoundCloud erhält dadurch die Information, dass Sie mit
        Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den „Like-Button“
        oder „Share-Button“ anklicken während Sie in Ihrem SoundCloud-
        Benutzerkonto eingeloggt sind, können Sie die Inhalte dieser Website mit
        Ihrem SoundCloud-Profil verlinken und/oder teilen. Dadurch kann
        SoundCloud Ihrem Benutzerkonto den Besuch dieser Website zuordnen. Wir
        weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
        Inhalt der übermittelten Daten sowie deren Nutzung durch SoundCloud
        erhalten.
      </p>
      <p>
        Die Nutzung von SoundCloud erfolgt auf Grundlage des Art. 6 Abs. 1 lit.
        f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer
        möglichst umfangreichen Sichtbarkeit in den Sozialen Medien.
      </p>
      <p>
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
        SoundCloud unter:
        <a
          href="https://soundcloud.com/pages/privacy"
          target="_blank"
          rel="noopener noreferrer"
          >https://soundcloud.com/pages/privacy</a
        >.
      </p>
      <p>
        Wenn Sie nicht wünschen, dass SoundCloud den Besuch dieser Website Ihrem
        SoundCloud- Benutzerkonto zuordnet, loggen Sie sich bitte aus Ihrem
        SoundCloud-Benutzerkonto aus bevor Sie Inhalte des SoundCloud-Plugins
        aktivieren.
      </p>
      <h3>Spotify</h3>
      <p>
        Auf dieser Website sind Funktionen des Musik-Dienstes Spotify
        eingebunden. Anbieter ist die Spotify AB, Birger Jarlsgatan 61, 113 56
        Stockholm in Schweden. Die Spotify Plugins erkennen Sie an dem grünen
        Logo auf dieser Website. Eine Übersicht über die Spotify-Plugins finden
        Sie unter:
        <a
          href="https://developer.spotify.com"
          target="_blank"
          rel="noopener noreferrer"
          >https://developer.spotify.com</a
        >.
      </p>
      <p>
        Dadurch kann beim Besuch dieser Website über das Plugin eine direkte
        Verbindung zwischen Ihrem Browser und dem Spotify-Server hergestellt
        werden. Spotify erhält dadurch die Information, dass Sie mit Ihrer
        IP-Adresse diese Website besucht haben. Wenn Sie den Spotify Button
        anklicken während Sie in Ihrem Spotify-Account eingeloggt sind, können
        Sie die Inhalte dieser Website auf Ihrem Spotify Profil verlinken.
        Dadurch kann Spotify den Besuch dieser Website Ihrem Benutzerkonto
        zuordnen.
      </p>
      <p>
        Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
        DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
        ansprechenden akustischen Ausgestaltung seiner Website.
      </p>
      <p>
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
        Spotify:
        <a
          href="https://www.spotify.com/de/legal/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.spotify.com/de/legal/privacy-policy/</a
        >.
      </p>
      <p>
        Wenn Sie nicht wünschen, dass Spotify den Besuch dieser Website Ihrem
        Spotify-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
        Spotify-Benutzerkonto aus.
      </p>
      <h2>8. Online Marketing und Partnerprogramme</h2>
      <h3>Amazon Partnerprogramm</h3>
      <p>
        Die Betreiber dieser Website nehmen am Amazon EU- Partnerprogramm teil.
        Auf dieser Website werden durch Amazon Werbeanzeigen und Links zur Seite
        von Amazon.de eingebunden, an denen wir über Werbekostenerstattung Geld
        verdienen können. Amazon setzt dazu Cookies ein, um die Herkunft der
        Bestellungen nachvollziehen zu können. Dadurch kann Amazon erkennen,
        dass Sie den Partnerlink auf dieser Website geklickt haben.
      </p>
      <p>
        Die Speicherung von „Amazon-Cookies“ erfolgt auf Grundlage von Art. 6
        lit. f DSGVO. Der Websitebetreiber hat hieran ein berechtigtes
        Interesse, da nur durch die Cookies die Höhe seiner Affiliate-Vergütung
        feststellbar ist.
      </p>
      <p>
        Weitere Informationen zur Datennutzung durch Amazon erhalten Sie in der
        Datenschutzerklärung von Amazon:
        <a
          href="https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401</a
        >.
      </p>
      <h2>9. Zahlungsanbieter und Reseller</h2>
      <h3>PayPal</h3>
      <p>
        Auf dieser Website bieten wir u.a. die Bezahlung via PayPal an. Anbieter
        dieses Zahlungsdienstes ist die PayPal (Europe) S.à.r.l. et Cie, S.C.A.,
        22-24 Boulevard Royal, L-2449 Luxembourg (im Folgenden „PayPal“).
      </p>
      <p>
        Wenn Sie die Bezahlung via PayPal auswählen, werden die von Ihnen
        eingegebenen Zahlungsdaten an PayPal übermittelt.
      </p>
      <p>
        Die Übermittlung Ihrer Daten an PayPal erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. a DSGVO (Einwilligung) und Art. 6 Abs. 1 lit. b DSGVO
        (Verarbeitung zur Erfüllung eines Vertrags). Sie haben die Möglichkeit,
        Ihre Einwilligung zur Datenverarbeitung jederzeit zu widerrufen. Ein
        Widerruf wirkt sich auf die Wirksamkeit von in der Vergangenheit
        liegenden Datenverarbeitungsvorgängen nicht aus.
      </p>
      <h3>Klarna</h3>
      <p>
        Auf dieser Website bieten wir u.a. die Bezahlung mit den Diensten von
        Klarna an. Anbieter ist die Klarna AB, Sveavägen 46, 111 34 Stockholm,
        Schweden (im Folgenden „Klarna“).
      </p>
      <p>
        Klarna bietet verschiedene Zahlungsoptionen an (z. B. Ratenkauf). Wenn
        Sie sich für die Bezahlung mit Klarna entscheiden
        (Klarna-Checkout-Lösung), wird Klarna verschiedene personenbezogene
        Daten von Ihnen erheben. Details hierzu können Sie in der
        Datenschutzerklärung von Klarna unter folgendem Link nachlesen:
        <a
          href="https://www.klarna.com/de/datenschutz/"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.klarna.com/de/datenschutz/</a
        >.
      </p>
      <p>
        Klarna nutzt Cookies, um die Verwendung der Klarna-Checkout-Lösung zu
        optimieren. Die Optimierung der Checkout-Lösung stellt ein berechtigtes
        Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Cookies sind
        kleine Textdateien, die auf Ihrem Endgerät gespeichert werden und keinen
        Schaden anrichten. Sie verbleiben auf Ihrem Endgerät bis Sie sie
        löschen. Details zum Einsatz von Klarna-Cookies entnehmen Sie folgendem
        Link:
        <a
          href="https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf"
          target="_blank"
          rel="noopener noreferrer"
          >https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf</a
        >.
      </p>
      <p>
        Die Übermittlung Ihrer Daten an Klarna erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. a DSGVO (Einwilligung) und Art. 6 Abs. 1 lit. b DSGVO
        (Verarbeitung zur Erfüllung eines Vertrags). Sie haben die Möglichkeit,
        Ihre Einwilligung zur Datenverarbeitung jederzeit zu widerrufen. Ein
        Widerruf wirkt sich auf die Wirksamkeit von in der Vergangenheit
        liegenden Datenverarbeitungsvorgängen nicht aus.
      </p>
      <h3>Sofortüberweisung</h3>
      <p>
        Auf dieser Website bieten wir u.a. die Bezahlung mittels
        „Sofortüberweisung“ an. Anbieter dieses Zahlungsdienstes ist die Sofort
        GmbH, Theresienhöhe 12, 80339 München (im Folgenden „Sofort GmbH“).
      </p>
      <p>
        Mit Hilfe des Verfahrens „Sofortüberweisung“ erhalten wir in Echtzeit
        eine Zahlungsbestätigung von der Sofort GmbH und können unverzüglich mit
        der Erfüllung unserer Verbindlichkeiten beginnen.
      </p>
      <p>
        Wenn Sie sich für die Zahlungsart „Sofortüberweisung“ entschieden haben,
        übermitteln Sie die PIN und eine gültige TAN an die Sofort GmbH, mit der
        diese sich in Ihr Online-Banking-Konto einloggen kann. Sofort GmbH
        überprüft nach dem Einloggen automatisch Ihren Kontostand und führt die
        Überweisung an uns mit Hilfe der von Ihnen übermittelten TAN durch.
        Anschließend übermittelt sie uns unverzüglich eine
        Transaktionsbestätigung. Nach dem Einloggen werden außerdem Ihre
        Umsätze, der Kreditrahmen des Dispokredits und das Vorhandensein anderer
        Konten sowie deren Bestände automatisiert geprüft.
      </p>
      <p>
        Neben der PIN und der TAN werden auch die von Ihnen eingegebenen
        Zahlungsdaten sowie Daten zu Ihrer Person an die Sofort GmbH
        übermittelt. Bei den Daten zu Ihrer Person handelt es sich um Vor- und
        Nachname, Adresse, Telefonnummer(n), Email-Adresse, IP-Adresse und ggf.
        weitere zur Zahlungsabwicklung erforderliche Daten. Die Übermittlung
        dieser Daten ist notwendig, um Ihre Identität zweifelsfrei zu
        festzustellen und Betrugsversuchen vorzubeugen.
      </p>
      <p>
        Die Übermittlung Ihrer Daten an die Sofort GmbH erfolgt auf Grundlage
        von Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) und Art. 6 Abs. 1 lit. b
        DSGVO (Verarbeitung zur Erfüllung eines Vertrags). Sie haben die
        Möglichkeit, Ihre Einwilligung zur Datenverarbeitung jederzeit zu
        widerrufen. Ein Widerruf wirkt sich auf die Wirksamkeit von in der
        Vergangenheit liegenden Datenverarbeitungsvorgängen nicht aus.
      </p>
      <p>
        Details zur Zahlung mit Sofortüberweisung entnehmen Sie folgenden Links:
        <a
          href="https://www.sofort.de/datenschutz.html"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.sofort.de/datenschutz.html</a
        >
        und
        <a
          href="https://www.klarna.com/sofort/"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.klarna.com/sofort/</a
        >.
      </p>
      <h3>Paydirekt</h3>
      <p>
        Auf dieser Website bieten wir u.a. die Bezahlung mittels Paydirekt an.
        Anbieter dieses Zahlungsdienstes ist die Paydirekt GmbH, Hamburger Allee
        26-28, 60486 Frankfurt am Main, Deutschland (im Folgenden „Paydirekt“).
      </p>
      <p>
        Wenn Sie die Bezahlung mittels Paydirekt ausführen, erhebt Paydirekt
        verschiedene Transaktionsdaten und leitet diese an die Bank weiter, bei
        der Sie mit Paydirekt registriert sind. Neben den für die Zahlung
        erforderlichen Daten erhebt Paydirekt im Rahmen der
        Transaktionsabwicklung ggf. weitere Daten wie z. B. Lieferadresse oder
        einzelne Positionen im Warenkorb.
      </p>
      <p>
        Paydirekt authentifiziert die Transaktion anschließend mit Hilfe des bei
        der Bank hierfür hinterlegten Authentifizierungsverfahrens. Anschließend
        wird der Zahlbetrag von Ihrem Konto auf unser Konto überwiesen. Weder
        wir noch Dritte haben Zugriff auf Ihre Kontodaten.
      </p>
      <p>
        Details zur Zahlung mit Paydirekt entnehmen Sie den AGB und den
        Datenschutzbestimmungen von Paydirekt unter:
        <a
          href="https://www.paydirekt.de/agb/index.html"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.paydirekt.de/agb/index.html</a
        >.
      </p>
      <h2>10. Eigene Dienste</h2>
      <h3>Umgang mit Bewerberdaten</h3>
      <p>
        Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B. per
        E-Mail, postalisch oder via Online-Bewerberformular). Im Folgenden
        informieren wir Sie über Umfang, Zweck und Verwendung Ihrer im Rahmen
        des Bewerbungsprozesses erhobenen personenbezogenen Daten. Wir
        versichern, dass die Erhebung, Verarbeitung und Nutzung Ihrer Daten in
        Übereinstimmung mit geltendem Datenschutzrecht und allen weiteren
        gesetzlichen Bestimmungen erfolgt und Ihre Daten streng vertraulich
        behandelt werden.
      </p>
      <p><strong>Umfang und Zweck der Datenerhebung </strong></p>
      <p>
        Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre damit
        verbundenen personenbezogenen Daten (z. B. Kontakt- und
        Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von
        Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die
        Begründung eines Beschäftigungsverhältnisses erforderlich ist.
        Rechtsgrundlage hierfür ist § 26 BDSG-neu nach deutschem Recht
        (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b
        DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine Einwilligung
        erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die Einwilligung ist
        jederzeit widerrufbar. Ihre personenbezogenen Daten werden innerhalb
        unseres Unternehmens ausschließlich an Personen weitergegeben, die an
        der Bearbeitung Ihrer Bewerbung beteiligt sind.
      </p>
      <p>
        Sofern die Bewerbung erfolgreich ist, werden die von Ihnen eingereichten
        Daten auf Grundlage von § 26 BDSG-neu und Art. 6 Abs. 1 lit. b DSGVO zum
        Zwecke der Durchführung des Beschäftigungsverhältnisses in unseren
        Datenverarbeitungssystemen gespeichert.
      </p>
      <p><strong>Aufbewahrungsdauer der Daten</strong></p>
      <p>
        Sofern wir Ihnen kein Stellenangebot machen können, Sie ein
        Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen, behalten wir
        uns das Recht vor, die von Ihnen übermittelten Daten auf Grundlage
        unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) bis zu 6
        Monate ab der Beendigung des Bewerbungsverfahrens (Ablehnung oder
        Zurückziehung der Bewerbung) bei uns aufzubewahren. Anschließend werden
        die Daten gelöscht und die physischen Bewerbungsunterlagen vernichtet.
        Die Aufbewahrung dient insbesondere Nachweiszwecken im Falle eines
        Rechtsstreits. Sofern ersichtlich ist, dass die Daten nach Ablauf der
        6-Monatsfrist erforderlich sein werden (z.B. aufgrund eines drohenden
        oder anhängigen Rechtsstreits), findet eine Löschung erst statt, wenn
        der Zweck für die weitergehende Aufbewahrung entfällt.
      </p>
      <p>
        Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine
        entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt haben
        oder wenn gesetzliche Aufbewahrungspflichten der Löschung
        entgegenstehen.
      </p>
    </div>
    <div class="backtohome">
      <a class="home" routerLink="/">
        <img
          class="logo-img-buttom"
          src="/assets/logo.png"
          style="vertical-align: middle"
          alt="HOME"
        />
        home
      </a>
    </div>
  </div>
</section>
