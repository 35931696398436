<footer class="footer">
  <div class="container" fxLayout="column" fxLayoutAlign="center center">
    <img class="logo-img" alt="Logo" src="/assets/logo.png" />
    <p class="copyright">MA-Technology &copy; Copyright 2021</p>
    <!-- <p class="social-info">Finde mehr über mich heraus.</p> -->
    <div
      class="social-icons"
      fxLayout="row"
      fyLayoutAlign="center center"
      fxLayoutGap="24px"
    >
      <a
        *ngFor="let soc of social"
        target="_blank"
        rel="noopener noreferrer"
        href="{{ soc.link }}"
        fxLayout="column"
        fxLayoutGap="8px"
        fxLayoutAlign="center center"
      >
        <img
          class="social-icon icon"
          src="assets/icons/social/{{ soc.icon }}"
          alt="{{ soc.name }}"
        />
        <div class="social-name">{{ soc.name }}</div>
      </a>
    </div>
    <div
      class="links"
      fxLayout.gt-sm="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center center"
      fxLayoutGap.gt-sm="48px"
      fxLayoutGap.lt-md="8px"
    >
      <a class="link" routerLink="/legal">Impressum</a>
      <a class="link" routerLink="/privacy">Datenschutz</a>
      <a class="link" routerLink="/login">Admin LogIn</a>
    </div>
  </div>
</footer>
