<section class="section about-section">
  <div class="container">
    <h2 class="section-heading">Über Mich</h2>
    <div
      class="content-wrapper"
      fxLayout.gt-xs="row"
      fxLayout.xs="column"
      fxLayoutAlign="center center"
      fxLayoutGap="48px"
    >
      <div
        class="text-wrapper"
        fxFlex.gt-xs="50%"
        fxFlex.xs="100%"
        fxLayoutAlign.xs="center center"
        fxLayout="column"
      >
        <p class="about-text">
          Ein berufserfahrenes und engagiertes Profil mit einer nachgewiesenen
          Erfolgsbilanz in der Automatisierung. Über sieben Jahre Tätig als
          Projektleiter, Roboterprogrammentwickler und Inbetriebnehmer in der
          Roboter- und Anlagentechnik bei der Firma Daimler AG. Seit über vier
          Jahren in der Selbständigkeit als Roboterprogrammentwickler und
          Inbetriebnehmer von Robotersystemen.
          <br /><br />Meine Stärken: Strukturiertes Herangehen an komplexe
          Projekte sowie ausgeprägte Fähigkeit, wichtige Details frühzeitig zu
          erkennen und zu berücksichtigen.
        </p>
      </div>
      <div
        class="image-wrapper"
        fxFlex.gt-xs="50%"
        fxFlex.xs="100%"
        fxLayoutAlign.gt-xs="start"
        fxLayoutAlign.xs="center"
      >
        <img
          class="about-image"
          src="/assets/img/team/anto.jpg"
          alt="Antonio Maioriello"
        />
      </div>
    </div>
    <div></div>

    <div class="container-button">
      <div class="cv-button">
        <a
          class="text-color"
          target="_blank"
          href="/assets/cv/CV_Antonio_Maioriello.pdf"
          >Zu meinem Lebenslauf</a
        >
      </div>
    </div>

    <!-- <a class="cv-button" routerLink="/cv">Zu meinem Lebenslauf</a> -->
  </div>
</section>
