import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import WebViewer from '@pdftron/webviewer';

@Component({
  selector: 'app-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.scss'],
})
export class CvComponent implements AfterViewInit {
  @ViewChild('viewer') viewerRef: ElementRef;

  constructor() {}

  ngAfterViewInit(): void {
    WebViewer(
      {
        path: '../assets/lib',
        initialDoc: 'assets/cv/CV_Antonio_Maioriello_upload.pdf',
      },
      this.viewerRef.nativeElement
    ).then(function (instance) {
      const documentViewer = instance.Core.documentViewer;
    });
  }
}
