import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ReplaySubject, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { navItemsFadeInLeft } from 'src/app/app.animations';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [navItemsFadeInLeft],
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Input() sections = null;
  activeRoute? = '/';
  bpSub: Subscription;
  isOpen? = false;
  isMobile? = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private sharedService: SharedService,
    private bpObserver: BreakpointObserver,
    private router: Router,
    private vpScroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    this.startBreakPointSub();

    this.router?.events
      .pipe(
        filter((event?) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe((event?: any) => {
        this.activeRoute = event.url;
        this.vpScroller.scrollToPosition([0, 0]);
      });
  }

  startBreakPointSub() {
    this.bpSub = this.bpObserver
      .observe(['(max-width: 959px)'])
      .subscribe((result) => {
        if (result.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  scrollToAnchor(anchor: string) {
    this.sharedService?.scrollToAnchor(anchor);
  }

  onMenu() {
    this.isOpen = !this.isOpen;
    console.log('der url ist ' + this.activeRoute);
  }

  endBreakPointSub() {
    this.bpSub?.unsubscribe();
  }

  ngOnDestroy() {
    this.destroyed$?.next(true);
    this.destroyed$?.complete();
  }
}
