<div class="credits">
  <div class="container" fxLayoutAlign="center center">
    <div class="created-by">
      Developed by
      <a
        class="link"
        href="https://angulex.dev/"
        target="_blank"
        and
        rel="noopener noreferrer"
        >Angulex
      </a>
      &
      <a
        class="link-ma"
        href="https://ma-technology.de/"
        target="_blank"
        and
        rel="noopener noreferrer"
      >
        MA-Technology</a
      >.
    </div>
  </div>
</div>
