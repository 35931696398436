export const FEATURES = [
  {
    icon: 'layer-group-solid.svg',
    name: 'Komplexe Palettierungen',
    description:
      'Intelligente Programmierung für eine Selbständige Palettierung.',
  },
  {
    icon: 'play-solid.svg',
    name: 'Prozess',
    description: 'Benutzerdefinierte Operationen.',
  },
  {
    icon: 'comments-regular.svg',
    name: 'Schnittstellenoptimierung',
    description:
      'Bestmöglichste Kommunikation zwischen Roboter und Peripherien.',
  },
  {
    icon: 'clock-regular.svg',
    name: 'Systemzykluszeit',
    description:
      'Schnelle Taktzeitoptimierung dank detaillierter Systemanalyse.',
  },
  {
    icon: 'search-solid.svg',
    name: 'Programmoptimierung',
    description:
      'Fehlerhafte Programme erkennen und durch optimierte codes ersetzen.',
  },
  {
    icon: 'smile-regular.svg',
    name: 'Benutzerfreundlich',
    description: 'Neue oder bestehende Programme benutzerfreundlich machen.',
  },
  {
    icon: 'book-open-solid.svg',
    name: 'Systemschulung',
    description: 'Individuelle Schulungen für Ihre Mitarbeiter.',
  },
  {
    icon: 'desktop-solid.svg',
    name: 'Roboterprogramme',
    description: 'Entwicklung von kundenspezifischen Roboterprogrammen.',
  },
];
