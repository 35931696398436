export const SECTIONS = [
  {
    name: 'home',
    anchor: 'hero',
  },
  {
    name: 'programmierdienstleistungen',
    anchor: 'features',
  },
  // {
  //   name: 'clients',
  //   anchor: 'clients',
  // },
  {
    name: 'über mich',
    anchor: 'about',
  },
  // {
  //   name: 'testimonials',
  //   anchor: 'testimonials',
  // },
  {
    name: 'kontakt',
    anchor: 'contact',
  },
];
