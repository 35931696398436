<section class="section cta-section">
  <div
    class="container"
    fxLayout.gt-xs="row"
    fxLayout.lt-sm="column"
    fxLayoutGap="32px"
    fxLayoutAlign="center center"
  >
    <div
      class="wrapper"
      fxFlex="80%"
      fxLayout="column"
      fxLayoutGap="16px"
      fxLayoutAlign="center center"
    >
      <h3 class="section-heading">
        Kontaktieren Sie jetzt <br />
        MA-Technology!
      </h3>
      <p class="comment">
        Und lassen auch Sie sich von unseren individuellen Lösungen begeistern.
      </p>
    </div>
    <div class="wrapper" fxFlex="20%" fxLayoutAlign="center center">
      <a class="btn" [routerLink]="" (click)="scrollToAnchor('contact')">
        Kontakt
      </a>
    </div>
  </div>
</section>
